import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { consoleLog } from "../../utils/log-utils";

function PaymentItem(props) {
  const [data] = useState(props.item);
  const navigate=useNavigate();
  consoleLog("data..", data);
  const storeInfoPage = () => {
    // dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/uniqueStore", {
      state: {
        storeId: data.storeId,
        storeName: data.storeName,
        storeAddress: data.deliveryCity,
        event:"orderWisePayments"
      },
    });
  };
  const deliveryPage = () => {
    // dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/uniqueDelivery", {
      state: {
        deliveryId: data.storeId,
        dName: data.storeName,
        
        event:"payments"
      },
    });
  };
  return (
    <>
      <tr>
        <td>{props.sno}</td>
       
        {/* <td>#Svr210524</td> */}
        <td>{data.duration}</td>
        <td onClick={()=>storeInfoPage()}>
          <Link>{data.storeName}</Link>
        </td>
        <td>{data.ordersCount}</td>
        <td>{data.orderPayment}</td>
        
        
        <td onClick={storeInfoPage}><Link>{data.storePayment}</Link></td>
        <td><Link>{data.dbCharges}</Link></td>
        <td><Link>{data.foPayment}</Link></td>
        <td>{data.finalPayShare}</td>
        {/* <td>{data.payment_status}</td> */}
        {/* <td>{data.transaction_date}</td>
        <td>{data.transaction_id}</td> */}
      </tr>
    </>
  );
}

export default PaymentItem;
