import { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useLocation, useNavigate } from "react-router-dom";
import { useToastMsg } from "../../hooks/useToastMsg";
import AutoComplete from "react-google-autocomplete";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { COUPONS_API_PATH } from "../../../network/config/apiPaths";
import { consoleLog } from "../../../utils/log-utils";

function NewLocationCoupon() {
  const naviagte = useNavigate();
  const location=useLocation();
  const couponData=location.state!=null?location.state.product:"";
  consoleLog("coupon Item",couponData)
  const [inputValidation, setInputValidation] = useState({
    discount: couponData!=""?couponData.offer_percentage:"",
    couponName: couponData!=""?couponData.name:"",
    from: couponData!=""?couponData.start_date:"",
    to: couponData!=""?couponData.end_date:"",
    couponCode: couponData!=""?couponData.code:"",
    max_limit: couponData!=""?couponData.max_limit:"",
    minOrder: couponData!=""?couponData.min_order_value:"",
    claims: couponData!=""?couponData.number_of_claims:"",
    image: "",
    terms: couponData!=""?couponData.terms:"",
    checking: false,
    locationName:couponData!=""?couponData.loation:""
  });
  const [cityName, setCity] = useState(couponData!=""?couponData.location:"");
  const [areaName, setAreaName] = useState(couponData!=""?couponData.location:"");

  const [latitude, setLatitude] = useState(couponData!=""?couponData.latitude:"");
  const [longitude, setLongitude] = useState(couponData!=""?couponData.longitude:"");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValidation({
      ...inputValidation,
      [name]: value,
    });
  };

  const reset = () => {
    setInputValidation({
      ...inputValidation,
      couponType: "",
      storeSearch: "",
      couponName: "",
      selectCategory: "",
      selectItems: "",
      discount: "",
      couponCode: "",
      from: "",
      to: "",
      max_limit: "",
      minOrder: "",
      claims: "",
      image: "",
      terms: "",
      checking: false,
    });
  };

  const [success, warning] = useToastMsg(
    couponData===""?"Coupon Successfully Created":"Coupon Successfully Updated",
    "Rquired All Input Feilds",
    reset
  );

  const Inputvalidation = () => {

    inputValidation.discount === "" ||
      inputValidation.from === "" ||
      inputValidation.to === "" ||
      inputValidation.minOrder === "" ||
      inputValidation.claims === "" ||
      inputValidation.couponName === "" ||
      inputValidation.couponCode === "" ||
      inputValidation.max_limit === "" ||
      inputValidation.terms === "" ||
      areaName === "" || cityName === "" ||
      inputValidation.checking === false
      ? warning()
      : LocationCOuponCreate();
  };
  const updatePlaceInfo = (place) => {
    if (place && place.address_components != null) {
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        switch (addressType) {
          case "locality":
            setAreaName(place.address_components[i].long_name)

            break;
          case "administrative_area_level_3":
            setCity(place.address_components[i].long_name)


            break;
          // case "country":
          //   setCountry(place.address_components[i].long_name);
          //   break;
        }
      }
    }

    consoleLog("place..", place)

    setLatitude(place.geometry.location.lat())
    setLongitude(place.geometry.location.lng())
  };
  const labelStyle = {
    fontWeight: "bold",

  };
  async function LocationCOuponCreate() {
    const data = await fetch(API_CONFIG.API_HOST + COUPONS_API_PATH.CREATE_COUPON, {
      method: "POST",
      headers: API_HEADERS,
      body:couponData===""? JSON.stringify({
        name: inputValidation.couponName,
        storeId: "", //only for Store
        code: inputValidation.couponCode,
        offerPercentage: inputValidation.discount,
        description: inputValidation.description,
        uniqueUser: "NO", // YES or NO
        type: "LOCATION", // ALL / LOCATION / STORE
        limitApply: "NO", // YES or NO
        limitCount: "0",
        latitude: latitude,
        longitude: longitude,
        maxLimit: inputValidation.max_limit, // offer max upto optional.
        startDate: inputValidation.from,
        endDate: inputValidation.to,
        daysLimit: "YES", // YES or NO, if days limit yes-> start and end date consider
        minOrderValue: inputValidation.minOrder,
        claims: inputValidation.claims,
        terms:inputValidation.terms
      }):JSON.stringify({
        name: inputValidation.couponName,
        storeId: "", //only for Store
        code: inputValidation.couponCode,
        offerPercentage: inputValidation.discount,
        description: inputValidation.description,
        uniqueUser: "NO", // YES or NO
        type: "LOCATION", // ALL / LOCATION / STORE
        limitApply: "NO", // YES or NO
        limitCount: "0",
        latitude: latitude,
        longitude: longitude,
        maxLimit: inputValidation.max_limit, // offer max upto optional.
        startDate: inputValidation.from,
        endDate: inputValidation.to,
        daysLimit: "YES", // YES or NO, if days limit yes-> start and end date consider
        minOrderValue: inputValidation.minOrder,
        claims: inputValidation.claims,
        terms:inputValidation.terms,
        id:couponData.id
      })
    })

    consoleLog("data", data)

    consoleLog("status code", data.status)
    if (data.status === 200) {
      success()
    }
  }


  return (
    <>
      <main className="container-fluid dashboard">
        {/* <div className="row m-3">
          <div className="col-4">
            <select
              className="form-control"
              name="couponType"
              value={inputValidation.couponType}
              onChange={handleChange}
            >
              <option>{"--Coupon Type--"}</option>
              <option>Almonds</option>
              <option>Badham</option>
            </select>

            {inputValidation.couponType === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>

          <div className="col-4">
            <input
              type="search"
              className="form-control"
              placeholder="Store Search"
              name="storeSearch"
              value={inputValidation.storeSearch}
              onChange={handleChange}
            />

            {inputValidation.storeSearch === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
        </div> */}

        <div className="row m-3">
          <div className="col-12 col-md-4 bottom-margin">
          <label>Coupon Name</label>
            <input
              className="form-control"
              name="couponName"
              type="text"
              placeholder="Coupon Name"
              value={inputValidation.couponName}
              onChange={handleChange}
            />

            {inputValidation.couponName === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-12 col-md-4 bottom-margin">
          <label>Coupon Code</label>
            <input
              className="form-control"
              name="couponCode"
              type="text"
              placeholder="Coupon Code"
              value={inputValidation.couponCode}
              onChange={handleChange}
            />

            {inputValidation.couponCode === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-12 col-md-4">
          <label>Maximum Limit</label>
            <input
              className="form-control"
              name="max_limit"
              type="text"
              placeholder="Maximum limit"
              value={inputValidation.max_limit}
              onChange={handleChange}
            />
          </div>
          {/* <div className="col-4">
            <select
              className="form-control"
              name="selectCategory"
              type="text"
              value={inputValidation.selectCategory}
              onChange={handleChange}
            >
              <option>{"--Select Category--"}</option>
              <option>All Seeds</option>
              <option>Nuts</option>
            </select>

            {inputValidation.selectCategory === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-4">
            <select
              className="form-control"
              name="selectItems"
              value={inputValidation.selectItems}
              onChange={handleChange}
            >
              <option>{"--Select Items--"}</option>
              <option>mirchi</option>
            </select>

            {inputValidation.selectItems === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div> */}
        </div>
        <div className="row m-3">
          <div className="col-sm-12 col-xs-12 col-md-6">
            <div className="form-group m-0">
              <label style={labelStyle}>Search City/Town</label>{" "}
              {cityName}
              <AutoComplete
                className="form-control"
                style={{ width: "100%" }}
                name="locationName"
                value={inputValidation.locationName}

                options={{
                  types: ["geocode", "establishment"],
                }}
                onChange={(e) => handleChange(e)}
                apiKey={"AIzaSyCB14naEHjDiVBFLEqhPBMUA9eqg_4yRdk"}
                onPlaceSelected={(place) => updatePlaceInfo(place)}
                placeholder="Enter city name"
              />
              {(areaName === "" || cityName === "") && <span className="text-danger">*Required</span>}
            </div>
          </div>
        </div>

        <div className="row m-3">
          <div className="col-12 col-md-4 col-lg-2">
            <label>Discount</label>
            <input
              type="number"
              placeholder="%"
              className="form-control"
              name="discount"
              value={inputValidation.discount}
              onChange={handleChange}
            />

            {inputValidation.discount === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-12 col-md-7 col-lg-5">


            <div className="d-flex flex-row">
              <div style={{ marginRight: 5 }}>
                <label>From</label>
                <input
                  type="date"
                  placeholder="From"
                  className="form-control"
                  name="from"
                  value={inputValidation.from}
                  onChange={handleChange}
                />

                {(inputValidation.from === "" || inputValidation.to === "") && (
                  <span className="text-danger">*Required</span>
                )}
              </div>
              <div>
                <label>To</label>
                <input
                  type="date"
                  placeholder="From"
                  className="form-control"
                  name="to"
                  value={inputValidation.to}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2">
            <label>Min Order</label>
            <input
              type="number"
              placeholder="Rs 300"
              className="form-control"
              name="minOrder"
              value={inputValidation.minOrder}
              onChange={handleChange}
            />

            {inputValidation.minOrder === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-6 col-lg-2">
            <label>Number of Claims</label>
            <input
              type="number"
              placeholder="5"
              className="form-control"
              name="claims"
              value={inputValidation.claims}
              onChange={handleChange}
            />

            {inputValidation.claims === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
        </div>

        <div className="row m-3">
          <div className="col-12 col-md-6">
            <label>Description</label>
            <input
              type="text"
              className="form-control"
              style={{ border: "1px solid #80df6d", height: 150 }}
            />
          </div>
          <div className="col-md-6 col-12">
            <label>Terms and Conditions</label>
            <textarea
              type="text"
              name="terms"
              rows="10"
              cols="100"
              value={inputValidation.terms}
              onChange={handleChange}
              className="form-control"
              style={{ border: "1px solid #80df6d", height: 150 }}
            ></textarea>
            {inputValidation.terms === "" && (
              <span className="text-danger">*Required</span>
            )}

          </div>
        </div>

        <div className="row m-3">

          <div className="col-12 col-lg-6">
            <input
              id="termsConditions"
              onClick={(e) =>
                setInputValidation({
                  ...inputValidation,
                  checking: e.target.checked,
                })
              }
              type="checkbox"
              style={{ width: 20, marginTop: 50, border: "1px solid #80df6d" }}
            />
            <label htmlFor="termsConditions">
              I am legally responsible for this business and have permission to
              create this coupon.{" "}
            </label>
            <br />
            {inputValidation.checking === false && (
              <span className="text-danger">*Required</span>
            )}
            <div className="d-flex flex-row  m-5">
              <button
                className="btn"
                style={{
                  border: "1px solid black",
                  marginRight: 5,
                  width: 100,
                }}
                onClick={() => naviagte(-1)}
              >
                Back
              </button>
              {
                couponData===""? <button className="btn background-color-change" onClick={Inputvalidation}>
                Generate
              </button>: <button className="btn background-color-change" onClick={Inputvalidation}>
               Update
              </button>
              }
             
            </div>
            <ToastContainer />
            {
              consoleLog("final output..", inputValidation)}
          </div>
        </div>
      </main>
    </>
  );
}

export default NewLocationCoupon;
