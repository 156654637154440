import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function LocationStoreItem(props){
    const navigate=useNavigate();
    const [product]=useState(props.item)

    // const listItem = () => {
    //     // dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    //     navigate("/uniqueStore", {
    //       state: {
    //         storeId: product.id,
    //         storeName: product.name,
    //         storeAddress: product.city,
    //       },
    //     });
    //   };
    return (
        <>
          <tr>
            <td>{props.sno}</td>
           
            <td>{product.categoryName}</td>
            <td>
              {product.name}
            </td>
            <td>{product.location_name}</td>
            <td>{product.city}</td>
            <td>{product.contact_name}</td>
            <td>{product.frName}</td>
            <td>
              {product.contact_one}<br />
              {product.contact_two}
            </td>
          
            <td>{product.radius} km</td>
          </tr>
        </>
      );
}
export default LocationStoreItem