import { useEffect, useState } from "react";
import { FaImage, FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import {
  onChangeData,
  onChangeDelete,
} from "../../redux/slice/selectedItemsSlice";
import { consoleLog } from "../../utils/log-utils";

const SelectableStoreTagItem = (props) => {
  const [item] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(item.status);
  const [checkedStatus, setCheckedStatus] = useState(
    item.storeTagId === "NO" ? false : true
  );

  const dispatch = useDispatch();

  useEffect(() => {
    consoleLog("itemStatus NOT", `${item.storeTagId} --${item.name}`);
    if (checkedStatus) {
      dispatch(onChangeData(item.id));
    } else {
      dispatch(onChangeDelete(item.id));
    }
  }, [checkedStatus]);

  const onChangeStatus = (e) => {
    setCheckedStatus(!checkedStatus);
  };

  return (
    <div className="col-md-4" style={{ padding: "10px" }}>
      <input
        type="checkbox"
        defaultChecked={props.itemStatus}
        onChange={onChangeStatus}
        style={{ marginRight: "10px" }}
      />
      {item.name}
    </div>
    // <tr>
    //   <td className="align-middle">
    //     <p>{item.id}</p>
    //   </td>

    //   <td className="align-middle">
    //     <p>{item.name}</p>
    //   </td>

    //   <td className="align-middle" style={{ width: "30%" }}>
    //     <p>{item.address}</p>
    //   </td>

    //   <td className="align-middle">
    //     <div className="col-12">
    //       <p>
    //         {item.contact_name} <br /> {item.contact_one}
    //       </p>
    //     </div>
    //   </td>
    // </tr>
  );
};
export default SelectableStoreTagItem;
