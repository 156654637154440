import { useState } from "react";
import { FaEdit} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { consoleLog } from "../../../utils/log-utils";

function FranchiseDetailItem(props){
    const navigate=useNavigate();
    const item=useState(props.item)
    const temp=item[0]
    
    consoleLog("......",temp)
    const storeDetail=()=>{
        navigate("/uniqueStore",{
            state:{
                storeId:temp.id,
                storeName:temp.name,
                storeAddress:temp.city
            }
        })
    }
    return(
        <>
        <tr>
    
        <td>{props.sno} </td>
        <td>{temp.categoryName}</td>
        <td onClick={storeDetail}>
        <Link className="anchor-color-change">{temp.name}</Link>
        </td>
        <td>
           {temp.contact_one}<br/>
           {temp.contact_two}
        </td>
        
        <td className="align-middle">
            <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
                <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    checked={temp.status === "YES" ? true : false}
                />
            </div>
        </td>
        
        <td className="align-middle">
                    <div className="d-flex justify-content-center">
                    <span className="edit-icon-btn">
                        <FaEdit/>
                    </span>
                    {/* onClick={deleteItem} */}
                    {/* <span className="delete-icon-btn" >
                        <FaTrash/>
                        </span> */}
                    </div>
                </td>
      </tr>
        </>
    )
}
export default FranchiseDetailItem