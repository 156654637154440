import React, { useContext, useState } from "react";

import {
  FaBars,
  FaCcDinersClub,
  FaUserLock,
  FaCuttlefish,
  FaUserTie,
  FaUsers,
  FaStore,
  FaLemon,
  FaAlignJustify,
  FaImage,
  FaHashtag,
  FaCentercode,
  FaRegDotCircle,
  FaUser,
  FaUserCircle,
  FaCube,
} from "react-icons/fa";
import { FaClipboardUser } from "react-icons/fa6";
import {
  TbRecharging,
  TbCricket,
  TbPlayFootball,
  TbReportAnalytics,
  TbAlignBoxBottomCenterFilled,
} from "react-icons/tb";
import {
  AiFillDashboard,
  AiOutlineWallet,
  AiOutlineTransaction,
  AiOutlineDribbble,
} from "react-icons/ai";
import { GiHound, GiRingmaster } from "react-icons/gi";
import { GrTransaction } from "react-icons/gr";
import { CgGames } from "react-icons/cg";
import { FcSportsMode, FcCurrencyExchange } from "react-icons/fc";
import { PiNewspaperClippingThin } from "react-icons/pi";
import {
  MdSportsKabaddi,
  MdSportsTennis,
  MdSportsHockey,
  MdDashboard,
  MdCasino,
  MdAccountBox,
  MdSupportAgent,
  MdHistoryEdu,
} from "react-icons/md";
import { Navigate, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import SidebarLink from "./sidebar-link";
import { Container, Navbar } from "react-bootstrap";
import {
  checkUserAccess,
  isAuthenticated,
  logoutUser,
} from "../../network/service/UserService";
import { RiCouponFill } from "react-icons/ri";
import logo from '../../assets/logo.png'
import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { consoleLog } from "../../utils/log-utils";
import { useToastMsgWithOutFun } from "../hooks/useToastMsg";
import { ToastContainer } from "react-toastify";
import { isSuperAdmin } from "../../utils/userRoles";
let selctedMenu;
const Sidebar = ({ children }) => {
  let addressUrl = new URL(window.location.href);
  let pathName = addressUrl.pathname.split("/");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [subnav, setSubnav] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [subnavIndex, setSubnavIndex] = useState();

  const [roleList, setRoleList] = useState([
    { id: "1", name: "Super Admin" },
    // isSuperAdmin()&&{id: "2", name: "ADMIN" },
    { id: "3", name: "Marketing Executive" },
    { id: "4", name: "Store Admin" },
    { id: "6", name: "Customer Support" },
    { id: "7", name: "Delivery Boy" },
    { id: "9", name: "Franchise Owner" },
  ]);

  const[success,warning]=useToastMsgWithOutFun("Successfully Refreshed","Somthing wrong")

  const toggle = () => setIsOpen(!isOpen);
  const showSubNav = (mainIndex) => {
    setSubnav(!subnav);
    setSubnavIndex(mainIndex);
  };

  const logout = async () => {
    await localStorage.clear();
    
    await logoutUser();
    navigate("/login");
  };
  const profile = async () => {
    
    navigate("/profile");
  };
  const RefreshToken = async () => {
   let data = await axios.get(API_CONFIG.API_HOST+"Home/refreshFcmeAuthToken",{
    headers:API_HEADERS
   })
   success()
  };
  


  const menuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <MdDashboard />,
      subnav: [],
      roles: ["1", "2", "4","9","11",],
    },
    {
      path: "",
      name: "Products",
      icon: <FaLemon/>,
      subnav: [
        {
          path: "/categories",
          name: "Main Categories",
          icon: <FaRegDotCircle />,
          subnav: [],
          roles: ["1", "2", "10", "4", "9"],
        },

        {
          path: "/pcateg",
          name: "Product Categories",
          icon: <FaRegDotCircle />, 
          subnav: [],
          roles: ["1", "2", "10", "4", "9"],
        },

        // {
        //   path: "/tags",
        //   name: "Product Tags",
        //   icon: <MdDashboard />,
        //   subnav: [],
        //   roles: ["1", "2", "3", "4"],
        // },

        {
          path: "/uom",
          name: "UOM",
          icon: <FaBars/>,
          subnav: [],
          roles: ["1", "2", "4"],
        },

        {
          path: "/products",
          name: "Products",
          icon: <FaLemon />,
          subnav: [],
          roles: ["1", "2", "10", "4", "9", "6"],
        },
      ],
      roles: ["1", "2", "10", "4", "6"],
    },
    {
      path: "",
      name: "Tags",
      icon: <FaHashtag/>,
      subnav: [
        {
          path: "/tags",
          name: "Tags",
          icon: <FaHashtag />,
          subnav: [],
          roles: ["1", "2", "3", "4",],
        },

        // {
        //   path: "/cattags",
        //   name: "Category Tags",
        //   icon: <MdDashboard />,
        //   subnav: [],
        //   roles: ["1", "2", "3", "4"],
        // },

        {
          path: "/storetags",
          name: "Store Tags",
          icon: <FaHashtag />,
          subnav: [],
          roles: ["1", "2", "4"],
        },
      ],
      roles: ["1", "2"],
    },

    {
      path: "",
      name: "Banners",
      icon: <FaImage />,
      subnav: [
        {
          path: "/homeBanners",
          name: "Home Banners",
          icon: <FaImage />,
          subnav: [],
          roles: ["1", "2"],
        },
        {
          path: "/categoryBanners",
          name: "Category Banners",
          icon: <FaImage />,
          subnav: [],
          roles: ["1", "2"],
        },
        {
          path: "/promoBanners",
          name: "Promotional Banners",
          icon: <FaImage />,
          subnav: [],
          roles: ["1", "2"],
        },
      ],
      roles: ["1", "2"],
    },
    {
      path: "/location",
      name: "Locations",
      icon: <MdDashboard />,
      subnav: [],
      roles: ["1", "2","9"],
    },
    {
      path: "/stores",
      name: "Stores",
      icon: <FaStore />,
      subnav: [],
      roles: ["1", "2", "9", "6"],
    },
    {
      path: "/paymentsOverview",
      name: "Payments",
      icon: <FaStore />,
      subnav: [],
      roles: ["9"],
    },
    {
      path: "/franchiseSettlements",
      name: "Settlements",
      icon: <FaStore />,
      subnav: [],
      roles: [ "9"],
    },
    {
      path: "",
      name: "Coupons",
      icon: <RiCouponFill/>,
      subnav: [
        {
          path: "/all",
          name: "App Coupons",
          icon: <RiCouponFill />,
          subnav: [],
          roles: ["1", "2", "4"],
        },
        {
          path: "/storecoupons",
          name: "Store Coupons",
          icon: <RiCouponFill />,
          subnav: [],
          roles: ["1", "2", "4"],
        },
        {
          path: "/locationCoupons",
          name: "Location Coupons",
          icon: <RiCouponFill />,
          subnav: [],
          roles: ["1", "2", "4"],
        },
      ],
      roles: ["1", "2", "4"],
    },
    {
      path: "/orders",
      name: "Orders",
      icon: <MdCasino />,
      subnav: [],
      roles: ["1", "2", "4", "6"],
    },
    {
      path: "",
      name: "Payments",
      icon: <FaUserTie />,
      subnav: [
        {
          path: "/payments",
          name: "Payments",
          icon: <FaUserTie />,
          subnav: [],
          roles: ["1", "2", "4","11" ],
        },
        {
          path: "/storepayment",
          name: "Store Payments",
          icon: <FaUserTie />,
          subnav: [],
          roles: ["1", "2", "4","11"],
        },
        {
          path: "/paymentsFoList",
          name: "Franchise Payments",
          icon: <FaUserTie />,
          subnav: [],
          roles: ["1", "2", "4","11"],
        },
        {
          path: "/paymentDblist",
          name: "D Boy Payments",
          icon: <FaUserTie />,
          subnav: [],
          roles: ["1", "2", "4","11"],
        },
        
      ],
      roles: ["1", "2", "4","11", ],
    },
    {
      path: "",
      name: "Settlements",
      icon: <FaStore />,
      subnav: [
        {
          path: "/adminStoreSettlements",
          name: "Store Settlements",
          icon: <FaUserTie />,
          subnav: [],
          roles: ["1", "2", "4","11"],
        },
        {
          path: "/adminDbSettlements",
          name: "DB Settlements",
          icon: <FaUserTie />,
          subnav: [],
          roles: ["1", "2", "4","11"],
        },
        {
          path: "/adminFoSettlements",
          name: "Fo Settlements",
          icon: <FaUserTie />,
          subnav: [],
          roles: ["1", "2", "4","11"],
        },
      ],
      roles: ["1", "2", "4","11", ],
    },
   
    {
      path: "",
      name: "Users",
      icon: <FaUser />,
      subnav: [
        {
          path: "/customers",
          name: "Customers",
          icon: <FaUserCircle />,
          subnav: [],
          roles: ["1", "2", "3", "4"],
        },
        {
          path: "/franchiseOwner",
          name: "Franchise Owner",
          icon: <FaUserCircle />,
          subnav: [],
          roles: ["1", "2", "3", "4"],
        },
        {
          path: "/storeAdmins",
          name: "Store Admins",
          icon: <FaUserCircle />,
          subnav: [],
          roles: ["1", "2", "3", "4"],
        },
        {
          path: "/deliveryBoys",
          name: "Delivery Boys",
          icon: <FaUserCircle />,
          subnav: [],
          roles: ["1", "2", "3", "4"],
        },
        {
          path: "/team",
          name: "Team",
          icon: <FaUserCircle />,
          subnav: [],
          roles: ["1", "2", "3", "4"],
        },
      ],
      roles: ["1", "2"],
    },
    {
      path: "/reports",
      name: "Reports",
      icon: <FaUserTie />,
      subnav: [],
      roles: ["1", "2", "4","11",],
    },
    {
      path: "/appVersion Table",
      name: "App Version",
      icon: <MdDashboard />,
      subnav: [],
      roles: ["1", "2"],
    },
    
  ];

  return (
    <>
    <ToastContainer/>
      {pathName[1] !== "login" && isAuthenticated() ? (
        <div className="sidenav-layout">
          <div
            style={{ width: isOpen ? "60px" : "200px", transition: "all 0.5s" }}
            className="sidebar"
          >
            <div className="top_section">
              {/* <img
                style={{
                  display: isOpen ? "none" : "block",
                  height: 35,
                }}
                className="logo"
                src=""
              /> */}

              <p
                style={{
                  display: isOpen ? "none" : "block",
                  padding: "10px",
                  color: "#FFFFFF",
                  fontSize:"16px",
                  fontWeight:"bold"
                }}
              >
                Eat Protien
              </p>
              {/* <img src={logo} alt="" width="60%"/> */}
              <div
                style={{ marginLeft: isOpen ? "0px" : "50px" }}
                className="bars"
              >
                <FaBars onClick={toggle} />
              </div>
            </div>
            <div className="mnu">
              {menuItem.map(
                (item, menuIndex) =>
                  // <SidebarLink item={item} isMenuExpand={isOpen} />
                  checkUserAccess(item.roles) && (
                    <SidebarLink
                      key={menuIndex}
                      item={item}
                      isMenuExpand={isOpen}
                      activeMenu={activeMenu}
                      selectedMenu={(menu) => {
                        setActiveMenu(menu);
                      }}
                    />
                  )
              )}
            </div>
          </div>

          <div
            className="dsply-desktp"
            style={{
              position: "Fixed",
              width: "100%",
              zIndex: 9,
              // marginLeft: isOpen ? "200px" : "60px"
            }}
          >
            <Navbar collapseOnSelect expand="lg">
              <div className="container-fluid">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto"></Nav>
                  <Nav className="me-auto"></Nav>
                  <Nav className="ml-auto">
                  <div>
                              <p>{localStorage.getItem("userName")}</p>
                              <p>{
                                roleList.map(item=>{
                                  if(localStorage.getItem("userRoleId")===item.id)
                                  return item.name
                                })
                              }</p>
                            </div>
                  </Nav>
                  <Nav className="">
                    <NavDropdown
                      title={
                        
                          
                            
                            
                              <img
                                style={{ height: 30, width: 28 }}
                                className="logo"
                                src="https://cdn.icon-icons.com/icons2/1919/PNG/512/avatarinsideacircle_122011.png"
                              />
                           
                            
                              
                           
                          
                        
                      }
                      id="collasible-nav-dropdown"
                    >
                      {/* <NavDropdown.Item
                        onClick={() => navigate("/changepassword")}
                      >
                        Change Password
                      </NavDropdown.Item> */}
                      <NavDropdown.Item onClick={()=>profile()}>
                        Profile
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => logout()}>
                        Logout
                      </NavDropdown.Item>
                      {
                      isSuperAdmin()?<NavDropdown.Item onClick={() => RefreshToken()}>
                      Refresh Auth Token
                    </NavDropdown.Item>:<></>
                      }
                      
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
              </div>
            </Navbar>
          </div>

          <div className="mble-dsply mble-dsply1">
            <div
              className="mnu"
              style={{ marginLeft: isMobileMenuOpen ? "0px" : "-100%" }}
            >
              {menuItem.map(
                (item, menuIndex) =>
                  // <SidebarLink item={item} isMenuExpand={isOpen} />
                  checkUserAccess(item.roles) && (
                    <SidebarLink
                      key={menuIndex}
                      item={item}
                      isMenuExpand={isOpen}
                      activeMenu={activeMenu}
                      selectedMenu={(menu) => {
                        setActiveMenu(menu);
                        setIsMobileMenuOpen(false);
                      }}
                    />
                  )
              )}
            </div>
            <div className="row p-1">
              <div className="col-4 mt-2 mb-1">
                <div
                  className="mobile-menu-toggle"
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                >
                  <FaBars />
                </div>
              </div>
              <div className="col-6 mt-3">
                <img
                  style={{ width: 140, marginLeft: -20 }}
                  className="logo"
                  src=""
                  onClick={() => navigate("/dashboard")}
                />
              </div>
              <div className="col-2 mt-3">
                <NavDropdown
                  title={
                    <img
                      style={{ height: 30, width: 28 }}
                      className="logo"
                      src="https://cdn.icon-icons.com/icons2/1919/PNG/512/avatarinsideacircle_122011.png"
                    />
                  }
                  id="collasible-nav-dropdown"
                >
                  {/* <NavDropdown.Item onClick={() => navigate("/changepassword")}>
                    Change Password
                  </NavDropdown.Item> */}
                  <NavDropdown.Item onClick={()=>profile()}>
                        Profile
                      </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => logout()}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </div>
          </div>

          <main
            className="main-layout"
            style={{
              marginLeft: isOpen ? "60px" : "200px",
            }}
          >
            {/* <div className="mble-dsply">
              <div className="row mt-3">
                <div className="col-6">
                  <label>Main Balance: </label>
                </div>
              </div>
            </div> */}

            <div>{children}</div>
          </main>
        </div>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
};

export default Sidebar;
