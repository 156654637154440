import { useNavigate } from "react-router-dom";
import BannerItem from "./banner-item";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { BANNER_API_PATH } from "../../../network/config/apiPaths";
import axios from "axios";
import { useEffect, useState } from "react";
import DeleteBanner from "../crudOperations/deleteBanner";
import { useDispatch, useSelector } from "react-redux";
import { changeStatus } from "../../../redux/slice/loaderSlice";
import {
  deleteStatus,
  updateFormInfo,
} from "../../../redux/slice/formUpdateSlice";
import { updateMessage } from "../../../redux/slice/toastSlice";
import { consoleLog } from "../../../utils/log-utils";

function HomeBanners() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [categoryList, setCategoryList] = useState([]);
  const [deleteBanner, setDeleteBanner] = useState(false);
  const showDeleteAlert = useSelector(deleteStatus);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    CategoryApi();
  }, []);

  const CategoryApi = async () => {
    setLoading(true);
    const data = await axios.get(
      API_CONFIG.API_HOST + BANNER_API_PATH.LIST_PATH + "HOME",
      {
        headers: API_HEADERS,
      }
    );
    
    consoleLog("data",data.data)
    setLoading(false);
    if (data.data.result != null) setCategoryList(data.data.result);
  };
  const onSubmit = () => {
    dispatch(
      updateMessage({
        display: true,
        message: "Record deleted",
      })
    );

    CategoryApi();
  };
  
  consoleLog("delete banner",deleteBanner)
  return (
    <>
      {showDeleteAlert && (
        <DeleteBanner
          onSubmit={() => {
            onSubmit();
          }}
        />
      )}
      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">Home Banners</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              <button
                type="button"
                className="mr-auto btn add-button-color"
                onClick={() => navigate("/addHomeBanner")}
              >
                Add New
              </button>
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-12 card-1 h-scroll">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Sno</th>
                  <th scope="col">Banner Type</th>

                  <th scope="col">Tags / Keyword</th>
                  <th scope="col">Banner Name</th>
                  <th scope="col">Banner Image</th>
                  
                  <th scope="col">Actions</th>
                </tr>
              </thead>

              <tbody>
                {isLoading ? (
                  <>Loading ..</>
                ) : categoryList && categoryList.length > 0 ? (
                  <>
                    {categoryList.map((item, index) => {
                      return (
                        <BannerItem
                          result={item}
                          key={item.id}
                          id={index + 1}
                        />
                      );
                    })}
                  </>
                ) : (
                  <> No Data</>
                )}
              </tbody>
            </table>
            {consoleLog("delete banner",deleteBanner)}
          </div>
        </div>
      </main>
    </>
  );
}

export default HomeBanners;
