import { useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useLocation, useNavigate } from "react-router-dom";
import { useToastMsg } from "../../hooks/useToastMsg";
import { listAction } from "../../../network/store/action/CategoryResponseAction";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import {
  COUPONS_API_PATH,
  STORE_API_PATH,
} from "../../../network/config/apiPaths";
import axios from "axios";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { consoleLog } from "../../../utils/log-utils";

function NewStoreCoupon() {
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const location=useLocation();
  const couponData=location.state!=null?location.state.product:"";
  consoleLog("coupon Item",couponData)

  const[err,setErr]=useState("");
  const [date,setDate]=useState("");
  const [inputValidation, setInputValidation] = useState({
    couponName: couponData!=""?couponData.name:"",
    max_limit: couponData!=""?couponData.max_limit:"",
    couponCode: couponData!=""?couponData.code:"",
    selectCategory: "",
    selectItems: "",
    discount: couponData!=""?couponData.offer_percentage:"",
    // couponType: "",
    from: couponData!=""?couponData.start_date:"",
    to: couponData!=""?couponData.end_date:"",

    minOrder: couponData!=""?couponData.min_order_value:"",
    claims: couponData!=""?couponData.number_of_claims:"",
    image: "",
    terms: couponData!=""?couponData.terms:"",
    checking: false,
    storeId: couponData!=""?couponData.store_id:"",
  });
  const [resultList, setResultList] = useState([]);
  const [storeResult, setStoreResult] = useState([]);
  const [selectedOption, setSelectedOption] = useState(couponData!=""?couponData.store_id:"");

  useEffect(() => {
    categoryData();
    StoreList();
  }, []);
  useEffect(()=>
  {
    datesValidation()
  },[inputValidation])

  const categoryData = async () => {
    await dispatch(
      listAction(
        {
          secId: "",
        },
        dispatch
      )
    ).then((reponse) => {
      if (reponse.result) {
        
        consoleLog("category response...", reponse.result)
        setResultList(reponse.result);
        setInputValidation({
          ...inputValidation,
          storeId: reponse.result[0].id,
        });
      }
    });
  };
  async function StoreList() {
    const data = await axios.get(
      API_CONFIG.API_HOST + STORE_API_PATH.STORE_LIST,
      {
        headers: API_HEADERS,
      }
    );
    
    consoleLog("store response...", data.data.result)
    setStoreResult(data.data.result);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValidation({
      ...inputValidation,
      [name]: value,
    });
  };

  const reset = () => {
    setInputValidation({
      ...inputValidation,
      selectCategory: "",
      selectItems: "",
      discount: "",
      couponName: "",
      max_limit: "",
      couponCode: "",
      from: "",
      to: "",

      minOrder: "",
      claims: "",
      image: "",
      terms: "",
      checking: false,
    });
  };

  const [success, warning] = useToastMsg(
    couponData===""?"Coupon Successfully Created":"Coupon Successfully Updated",
    "Rquired All Input Feilds",
    reset
  );

  const Inputvalidation = () => {
    // inputValidation.selectCategory === "" ||
    // inputValidation.selectCategory === "--Select Category--" ||
    // inputValidation.selectItems === "" ||
    inputValidation.couponCode === "" ||
    inputValidation.couponName === "" ||
    //  inputValidation.couponType === "" ||
    // inputValidation.selectItems === "--Select Items--" ||
    inputValidation.discount === "" ||
    inputValidation.from === "" ||
    inputValidation.to === "" ||
    inputValidation.minOrder === "" ||
    inputValidation.claims === "" ||
    inputValidation.terms === "" ||
    inputValidation.checking === false ||
    selectedOption === null
      ? warning()
      : StoreCOuponCreate();
  };
  const categoryItems = () => {
    return (
      <>
        {resultList.map((obj) => {
          return (
            <>
              <option key={obj.id} value={obj.name}>
                {obj.name}
              </option>
            </>
          );
        })}
      </>
    );
  };

  //   const storeItems = () => {
  //     return (
  //       <>
  //         <select
  //           value={inputValidation.storeId}
  //           name="storeId"
  //           onChange={handleChange}
  //           className="form-control"
  //         >
  //           {storeResult.map((obj) => {
  //             return (
  //               <option value={obj.id} key={obj.id}>
  //                 {obj.name}
  //               </option>
  //             );
  //           })}
  //         </select>
  //       </>
  //     );
  //   };
  async function StoreCOuponCreate() {
    const data = await fetch(
      API_CONFIG.API_HOST + COUPONS_API_PATH.CREATE_COUPON,
      {
        method: "POST",
        headers: API_HEADERS,
        body: couponData===""?JSON.stringify({
          name: inputValidation.couponName,
          storeId: selectedOption.id, //only for Store
          code: inputValidation.couponCode,
          offerPercentage: inputValidation.discount,
          description: inputValidation.description,
          uniqueUser: "NO", // YES or NO
          type: "STORE", // ALL / LOCATION / STORE
          limitApply: "NO", // YES or NO
          limitCount: "0",

          maxLimit: inputValidation.max_limit, // offer max upto optional.
          startDate: inputValidation.from,
          endDate: inputValidation.to,
          daysLimit: "YES", // YES or NO, if days limit yes-> start and end date consider
          minOrderValue: inputValidation.minOrder,
          claims: inputValidation.claims,
          terms:inputValidation.terms,
        }):JSON.stringify({
          name: inputValidation.couponName,
          storeId: selectedOption.id, //only for Store
          code: inputValidation.couponCode,
          offerPercentage: inputValidation.discount,
          description: inputValidation.description,
          uniqueUser: "NO", // YES or NO
          type: "STORE", // ALL / LOCATION / STORE
          limitApply: "NO", // YES or NO
          limitCount: "0",

          maxLimit: inputValidation.max_limit, // offer max upto optional.
          startDate: inputValidation.from,
          endDate: inputValidation.to,
          daysLimit: "YES", // YES or NO, if days limit yes-> start and end date consider
          minOrderValue: inputValidation.minOrder,
          terms:inputValidation.terms,
          claims: inputValidation.claims,
          id:couponData.id
        })
      }
    );
    
    consoleLog("data",data)
   
    consoleLog("status code", data.status)
    if (data.status === 200) {
      success();
      naviagte(-1);
    }
  }
  const datesValidation= () =>{
    let to=inputValidation.to;
    
      {
        consoleLog("to",inputValidation.to)
      }
      
        if(new Date(inputValidation.from)>new Date(to)){
          setErr("Enter Valid Date")
        }
        else{
          // <span className="text-danger">Enter valid date</span>
         setDate(to)
         setErr('')
        }
       
        
        
        
      
      {
        consoleLog("To date..", date)
      }
     
      
    
  }

  return (
    <>
      <main className="container-fluid dashboard">
        <h5>Create Store Coupon </h5>
        {/* <div className="row m-3">
                    <div className="col-4">
                        <select
                            className="form-control"
                            name="couponType"
                            value={inputValidation.couponType}
                            onChange={handleChange}
                        >
                            <option>{"--Coupon Type--"}</option>
                            <option>Almonds</option>
                            <option>Badham</option>
                        </select>

                        {inputValidation.couponType === "" && (
                            <span className="text-danger">*Required</span>
                        )}
                    </div>

                    <div className="col-4">

                        {storeItems()}

                    </div>
                </div> */}

        <div className="row m-3">
          <div className="col-12 col-md-4 bottom-margin">
            <label>Store Name</label>
            <Select
              options={storeResult}
              value={selectedOption !== "" ? selectedOption : ""}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => `${option.id}`}
              onChange={(sOption) => {
               
                consoleLog("selectedOptions", sOption)
                setSelectedOption(sOption);
              }}
              // isMulti
            />
          </div>

          <div className="col-12 col-md-4">
            <label>Coupon Name</label>
            <input
              className="form-control"
              name="couponName"
              type="text"
              placeholder="Coupon Name"
              value={inputValidation.couponName}
              onChange={handleChange}
            />

            {inputValidation.couponName === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-12 col-md-4 bottom-margin">
          <label>Coupon Code</label>
            <input
              className="form-control"
              name="couponCode"
              type="text"
              placeholder="Coupon Code"
              value={inputValidation.couponCode}
              onChange={handleChange}
            />

            {inputValidation.couponCode === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          {/* <div className="col-4">
            <select
              className="form-control"
              name="selectCategory"
              type="text"
              value={inputValidation.selectCategory}
              onChange={handleChange}
            >
              <option>{"--Select Category--"}</option>
              {categoryItems()}
            </select>

            {inputValidation.selectCategory === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-4">
            <select
              className="form-control"
              name="selectItems"
              value={inputValidation.selectItems}
              onChange={handleChange}
            >
              <option>{"--Select Items--"}</option>
              <option>mirchi</option>
            </select>

            {inputValidation.selectItems === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div> */}
        </div>

        <div className="row m-3">
          
          <div className="col-sm-6 col-xs-6 col-md-4 bottom-margin">
          <label>Maximum Limit</label>
            <input
              className="form-control"
              name="max_limit"
              type="text"
              placeholder="Maximum limit"
              value={inputValidation.max_limit}
              onChange={handleChange}
            />
          </div>

          <div className="col-sm-6 col-xs-6 col-md-4 bottom-margin">
            <label>Discount</label>
            <input
              type="number"
              placeholder="Discount %"
              className="form-control"
              name="discount"
              value={inputValidation.discount}
              onChange={handleChange}
            />

            {inputValidation.discount === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
        </div>

        <div className="row m-3">
          <div className="col-sm-4 col-xs-4 col-md-7 col-lg-5">
            {/* <h6>Validity</h6> */}
            <div className="d-flex flex-row">
            <div className="d-flex flex-row">
              <div style={{ marginRight: 5 }}>
                <label>From</label>
                <input
                  type="date"
                  placeholder="From"
                  className="form-control"
                  name="from"
                  value={inputValidation.from}
                  onChange={handleChange}
                />

                {(inputValidation.from === "" || inputValidation.to === "") && (
                  <span className="text-danger">*Required</span>
                )}
               
                {err !=="" && (
                  <span className="text-danger">{err}</span>
                )}
              </div>
            </div>
            <div className="d-flex flex-row">
              <div>
                <label>To</label>
                <input
                  type="date"
                  placeholder="From"
                  className="form-control"
                  name="to"
                  value={inputValidation.to}
                  onChange={handleChange}
                />
              </div>
            </div>
            </div>
          </div>

          {/* <div className="col-sm-4 col-xs-4 col-lg-2">
            {/* <h6>Validity</h6> */}

            {/* <div className="d-flex flex-row">
              <div>
                <label>To</label>
                <input
                  type="date"
                  placeholder="From"
                  className="form-control"
                  name="to"
                  value={inputValidation.to}
                  onChange={handleChange}
                />
              </div>
            </div> */}
          {/* </div>  */}
          <div className="col-4">
            <label>Min Order</label>
            <input
              type="number"
              placeholder="Rs 300"
              className="form-control"
              name="minOrder"
              value={inputValidation.minOrder}
              onChange={handleChange}
            />

            {inputValidation.minOrder === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-6 col-lg-2">
            <label>Number of Claims</label>
            <input
              type="number"
              placeholder="5"
              className="form-control"
              name="claims"
              value={inputValidation.claims}
              onChange={handleChange}
            />

            {inputValidation.claims === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
        </div>

        <div className="row m-3">
          <div className="col-12 col-md-6 col-lg-6">
            <label>Description</label>
            <input
              type="text"
              className="form-control"
              style={{ border: "1px solid #80df6d", height: 150 }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <label>Terms and Conditions</label>
            <textarea
              type="text"
              name="terms"
              rows="10"
              cols="100"
              value={inputValidation.terms}
              onChange={handleChange}
              className="form-control"
              style={{ border: "1px solid #80df6d", height: 150 }}
            ></textarea>

            {inputValidation.terms === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
        </div>

        <div className="row m-3">
          <div className="col-lg-6 col-12">
            <input
              id="termsConditions"
              onClick={(e) =>
                setInputValidation({
                  ...inputValidation,
                  checking: e.target.checked,
                })
              }
              type="checkbox"
              style={{ width: 20, marginTop: 50, border: "1px solid #80df6d" }}
            />
            <label htmlFor="termsConditions">
              I am legally responsible for this business and have permission to
              create this coupon.{" "}
            </label>
            <br />
            {inputValidation.checking === false && (
              <span className="text-danger">*Required</span>
            )}
            <div className="d-flex flex-row  m-5">
              <button
                className="btn"
                style={{
                  border: "1px solid black",
                  marginRight: 5,
                  width: 100,
                }}
                onClick={() => naviagte(-1)}
              >
                Back
              </button>
              {
                couponData===""?<button className="btn background-color-change" onClick={Inputvalidation}>
                Generate
              </button>:<button className="btn background-color-change" onClick={Inputvalidation}>
                Update
              </button>
              }
              
            </div>
            <ToastContainer />
            {
            consoleLog("final output..", inputValidation)}
          </div>
        </div>
      </main>
    </>
  );
}

export default NewStoreCoupon;
