import React, { useEffect, useState } from "react";

import axios from "axios";

import { useNavigate } from "react-router-dom";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { USER_API_PATH } from "../../../network/config/apiPaths";
import FranchiseItem from "./fanchise-item";
import { useDispatch, useSelector } from "react-redux";
import { deleteStatus } from "../../../redux/slice/formUpdateSlice";
import { updateMessage } from "../../../redux/slice/toastSlice";
import DeleteBanner from "../../banners/crudOperations/deleteBanner";
import { consoleLog } from "../../../utils/log-utils";

function FranchiseOwner() {
  const navigate=useNavigate();
  const dispatch=useDispatch();
  const [apiResult, setApiResult] = useState([]);

  const [searchVal, setSearchVal] = useState("");
 
  const [filterList, setFilterList] = useState([]);

  const [isLoading,setIsLoading] = useState(false)
  const showDeleteAlert=useSelector(deleteStatus);

  

  useEffect(() => {
    UserList();
  }, []);

  const UserList = async () => {
    setIsLoading(true)
    const data = await axios.get(
      API_CONFIG.API_HOST + USER_API_PATH.LIST_PATH+"9",
      {
        headers: API_HEADERS,
      }
    );
    setIsLoading(false)
    if(data.data.result){
    setApiResult(data.data.result);
    setFilterList(data.data.result)
    }
  };

  

  function handleSearchClick(e) {
    const searchTerm = e.target.value;
    setSearchVal(searchTerm);
    consoleLog("search Value", searchTerm);
      const filterBySearch = apiResult.filter((item) => {
        if (item.phone.includes(searchTerm)) {
          return item;
        }
      });
      setFilterList(filterBySearch);
   
  }


  {consoleLog("result",apiResult)}
  const onSubmit = () => {
    
    dispatch(
      updateMessage({
        display: true,
        message: "Record deleted",
      })
    );
    // setBannerList([]);
    UserList()

    // loadData();
  };



  {consoleLog("result",apiResult)}
  return (
    <>
    {showDeleteAlert && (
      <DeleteBanner 
        onSubmit={() => {
          onSubmit();
        }}
      />
    )}
    <main className="container-fluid dashboard">
      <div className="row m-3">
        <div className="row justify-content-around tble">
          <div className="col-6">
            <h3 className="dashboard-title">Franchise Owner</h3>
          </div>
          <div className="mr-auto col-6 text-end">
            <button
              type="button"
              className="mr-auto btn add-button-color"
              onClick={() => {navigate("/addNewUser")}}
            >
              + Add
            </button>
          </div>
        </div>
      </div>
      <div className="row m-3">
        <div className="col-12 col-md-3">
          <div className="form-group">
            <label>Search</label>
            <input
              type="number"
              name="name"
              className="form-control"
              value={searchVal}
              onChange={handleSearchClick}
              placeholder="Enter Phone No"
            />
          </div>
        </div>
        
        <div className="col-md-12 card-1 h-scroll">
          <table className="table table-hover align-middle">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Sno</th>
                <th scope="col">User Name</th>
                <th scope="col">Contact no</th>
                <th scope="col">Location</th>
                <th scope="col">Radius</th>
                <th scope="col">%</th>
                {/* <th scope="col">Status</th> */}
                <th scope="col">Actions</th>
              </tr>
            </thead>

            <tbody>
              {
                isLoading ? (
                  <>Loading</>
                ):
                filterList&&filterList.length>0?
              filterList.map((obj) => {
                return <FranchiseItem item={obj} key={obj.id} />;
              }):(
                <>No data</>
              )
            }
            </tbody>
          </table>
        </div>
      </div>
    </main>
    </>
  );
}

export default FranchiseOwner;