import { FaEdit, FaEye, FaRegEdit, FaTrash } from "react-icons/fa"
import { displayLargeImage, displayRemoveAlert, updateFormInfo } from "../../../redux/slice/formUpdateSlice";
import { useDispatch } from "react-redux";
import noImage from '../../../assets/no_image.jpg'
import { useRef, useState } from "react";
import { CouponImageUplaodRequest, productNxImageUplaodRequest } from "../../../network/service/ImageUploadService";
import { Link, useNavigate } from "react-router-dom";
import { getImagePath } from "../../../utils/util";
import { consoleLog } from "../../../utils/log-utils";

function LocationCouponsItem(props){
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const [product]=useState(props.item)
  const hiddenFileInput = useRef(null);
  const [file,setFile]=useState();

  const onImageClick = () => {
    dispatch(updateFormInfo({ imageUpload: true, formData: { product } }));
  };
 
  const limitApply = (start,end) =>{

    let currentDate=new Date();
    let startDate=new Date(start);
    let endDate=new Date(end);
   
    
    if(endDate<currentDate){
      return "expired"
    }
    if(startDate<=currentDate && currentDate<=endDate){
      return "InProgress"
    }
    if(startDate>currentDate){
      return "scheduled"
    }
   
  }

 const onNgImageClick = () => {
  hiddenFileInput.current.click();
};
const showLargeImage = () => {
  dispatch(
    displayLargeImage({ displayLargeImage: true, formData: { product } })
  );
};


const handleImageInputChange = (event) => {
  const fileUploaded = event.target.files[0];
  if (fileUploaded != null) {
    setFile(URL.createObjectURL(fileUploaded));
    uploadImage(fileUploaded);
  }
};

const uploadImage = async (file) => {
  const form_data = new FormData();
  form_data.append("file", file);
  let requestBody = {
    id: product.id,
    fileData: form_data,
  };
  await dispatch(CouponImageUplaodRequest(requestBody)).then(
    (response) => {
      
      consoleLog("response...",response)
    }
  );
};

 const deleteItem = () => {
  
  dispatch(displayRemoveAlert({ displayRemove: true, formData: { item:product },type:"coupon" }));
};
const couponStore=()=>{
  navigate("/CouponStore",{state:{storeId:product.store_id}})
}
const editItem = () => {
  // dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
  navigate("/addLocationCoupon", { state: { product } });
};
    return(
        <>
        <tr>
            <td>{props.sno}</td>
            <td>{product.created_at.substring(0,10)}</td>
            <td onClick={couponStore}><Link className="anchor-color-change">{product.code}</Link></td>
            <td>{product.offer_percentage}%</td>            <td className="align-middle">
          <div className="cat_mainwrap" onClick={onNgImageClick}>
          {
            product.image_path=== null ? null : (
              <div className="cat_mainwrap">
             {
            product.image_path === "" ? null :
              
               <div className="cat_btnwrap">
              <FaRegEdit className="faWhite" onClick={() => onNgImageClick()} />

              <FaEye className="faWhite" onClick={() => showLargeImage()} />
            </div>
             
          }
              <input
                type="file"
                onChange={handleImageInputChange}
                ref={hiddenFileInput}
                style={{ display: "none" }} // Make the file input element invisible
              />
              {file ? (
                <>
                  <img
                    src={file}
                    alt={product.name}
                    className="img-thumbnail"
                    width="50px"
                    height="50px"
                  />
                </>
              ) : product.image_path !== "" ? (
                <img
                  src={getImagePath(product.image_path)}
                  alt={product.name}
                  className="img-thumbnail"
                  width="50px"
                  height="50px"
                />
              ) : (
                <img
                  src={noImage}
                  alt={product.name}
                  className="img-thumbnail"
                  width="50px"
                  height="50px"
                />
              )}
            </div>
            )}

           
          </div>
        </td>
            
            <td>{product.start_date} to<br/> {product.end_date}</td>
            <td>
              {
                limitApply(product.start_date,product.end_date)
              }
          </td>
            <td>{product.used_count}</td>
            <td className="align-middle">
                <div className="d-flex justify-content-center"> 
                <span className="edit-icon-btn">
                  <FaEdit onClick={editItem}/>
                </span>
                <span className="delete-icon-btn" onClick={deleteItem}>
                  <FaTrash/>
                </span>
                </div>
            </td>
            
        </tr>
        </>
    )
}

export default LocationCouponsItem