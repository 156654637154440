import React, { useEffect, useState } from "react";
import OrderItem from "./unique store items/Order-Item";
import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { STORE_API_PATH } from "../../../network/config/apiPaths";
import { consoleLog } from "../../../utils/log-utils";
import { isFranchisLogin } from "../../../utils/userRoles";

function UniqueStoreOrders(props) {
  const [order] = useState(props.id);
  const [resultList, setResultList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectMonth, setMonth] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [err, setErr] = useState("");
  const [orderCount, setOrderCount] = useState([]);
  const [phoneFilter, setphoneFilter] = useState("");
  const [status,setStatus]=useState("");
  const month = [
    { id: "01", name: "JAN" },
    { id: "02", name: "FEB" },
    { id: "03", name: "MARCH" },
    { id: "04", name: "APRIL" },
    { id: "05", name: "MAY" },
    { id: "06", name: "JUNE" },
    { id: "07", name: "JULY" },
    { id: "08", name: "AUG" },
    { id: "09", name: "SEP" },
    { id: "10", name: "OCT" },
    { id: "11", name: "NOV" },
    { id: "12", name: "DEC" },
  ];
  const [statusList, setStatusList] = useState([
    { id: "0", name: "Pending" },
    { id: "1", name: "Accepted" },
    { id: "2", name: "Assgned" },
    { id: "3", name: "Picked-Up" },
    { id: "4", name: "Delivered" },
    { id: "6", name: "Cancelled" },
  ]);
  useEffect(() => {
    StoreOrdersApi();
    storeOrderCount();
  }, []);
  useEffect(()=>{
     filterOrderStatus(status);
  },[status])

  const StoreOrdersApi = async () => {
    setIsLoading(true);
    let data = await axios.get(
      API_CONFIG.API_HOST +
        STORE_API_PATH.UNIQUE_STORE_ORDERS +
        order +
        "&startDate=" +
        fromDate +
        "&endDate=" +
        toDate,
      {
        headers: API_HEADERS,
      }
    );
    consoleLog(data.data.result);
    setIsLoading(false);
    if (data.data.result !== null) {
      setResultList(data.data.result);
      setFilterList(data.data.result);
    } else {
      setResultList([]);
      setFilterList([]);
    }
  };

  const storeOrderCount = async () => {
    let data = await axios.get(
      API_CONFIG.API_HOST +
        STORE_API_PATH.UNIQUE_ORDERS_COUNT +
        `storeId=${order}&range=custom&startDate=${fromDate}&endDate=${toDate}`,
      {
        headers: API_HEADERS,
      }
    );
    consoleLog(data.data.result);
    setOrderCount(data.data.result);
  };

  const filterMonth = (e) => {
    setMonth(e.target.value);
    consoleLog(e.target.value);
    setIsLoading(true);
    let month = e.target.value;
    let data = resultList.filter((item) => {
      
      if (item.dated.substring(5, 7) == month) {
        return item;
      }
    });
    consoleLog("data..", data);
    setIsLoading(false);
    setFilterList(data);
  };

  const filterOrderStatus=async(status)=>{
    if(status===""){
      setFilterList(resultList)
    }
   else{
    let data=resultList.filter((item)=>{
      if(item.status===status){
        return item
      }
      })
      consoleLog(data);
      setFilterList(data);
   }
   
    
  }

  const filterFromDate = (e) => {
    setFromDate(e.target.value);
    if (new Date(e.target.value) > new Date(toDate))
      setErr("enter valid dates");
    else setErr("");
    //
    // setIsLoading(true);
    // let month = e.target.value;
    // let data = resultList.filter((item) => {
    //  
    //   if (item.dated === month) {
    //     return item;
    //   }
    // });
    // 
    // setIsLoading(false);
    // setFilterList(data);
  };

  const validateDates = (e) => {
    setToDate(e.target.value);
    if (new Date(fromDate) > new Date(e.target.value))
      setErr("enter valid dates");
    else setErr("");
  };

  const FilterByPhone = (e) => {
    setphoneFilter(e.target.value);
    
    consoleLog("value",e.target.value);
    setIsLoading(true);
    let pno = e.target.value;
    let data = resultList.filter((item) => {
      if(item.order_id.includes(pno)){
        return item;
      }
     
      
    });
    consoleLog("data..", data);
    setIsLoading(false);
    setFilterList(data);
  };

  return (
    <>
      <div className="row mb-3">
        {/* <div className='col-2'>
        <label>Month</label>
          <select value={selectMonth} className='form-control' onChange={filterMonth}>
            <option>{"--select month--"}</option>
            {
              month.map(item=>{
                return(
                  <option value={item.id}>{item.name}</option>
                )
              })
            }
          </select>
        </div> */}
        <div className="col-4 col-lg-2">
          <label>From</label>
          <input
            type="date"
            onChange={filterFromDate}
            value={fromDate}
            className="form-control"
          />
        </div>
        <div className="col-4 col-lg-2">
          <label>To</label>
          <input
            type="date"
            className="form-control"
            onChange={validateDates}
          />

          <span className="text-danger">{err}</span>
        </div>
        {/* <div className="col-3">
          <label></label>
          <input type="search" className="form-control" />
        </div> */}

        <div className="col-3">
          <label></label>
          <label></label>
          <br/>
          <button
            type="button"
            class="btn btn-danger me-2"
            onClick={() => StoreOrdersApi()}
          >
            Apply
          </button>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6 col-md-4 col-lg-2">
          <div className="dashboard-div dashboard-div-bg d-flex flex-column justify-content-center">
            {orderCount.received} <br /> Orders 
            Recieved
          </div>
        </div>
        <div className="col-6 col-md-4 col-lg-2">
          <div className="dashboard-div dashboard-div-bg d-flex flex-column justify-content-center">
            {orderCount.accepted} <br /> Orders Accepted
          </div>
        </div>
        <div className="col-6 col-md-4 col-lg-2">
          <div className="dashboard-div dashboard-div-bg d-flex flex-column justify-content-center">
            {orderCount.shipped} <br /> Orders  Shipped
          </div>
        </div>
        <div className="col-6 col-md-4 col-lg-2">
          <div className="dashboard-div dashboard-div-bg d-flex flex-column justify-content-center">
            {orderCount.delivered} <br /> Orders Delivered
          </div>
        </div>
        <div className="col-6 col-md-4 col-lg-2">
          <div className="dashboard-div dashboard-div-bg d-flex flex-column justify-content-center">
            {orderCount.cancelled} <br /> Orders Cancelled
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-3 mb-3">
          <input
            type="search"
            value={phoneFilter}
            onChange={FilterByPhone}
            className="form-control"
            placeholder="search by order id"
          />
        </div>
        <div className="col-12 col-md-3">
            <div className="form-group">
              
              <select
                name="status"
                className="form-select"
                onChange={(e) => setStatus(e.target.value)}
                value={status}
              >
                <option value="">{"--Select Status--"}</option>
                {statusList &&
                  statusList.length > 0 &&
                  statusList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

        <div className="col-md-12 card-1 h-scroll">
          <table className="table table-hover align-middle">
            <thead className="thead-dark">
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Date</th>
                <th scope="col">Order No</th>
                <th scope="col">Order Amount</th>
                <th scope="col">Customer Name</th>
                {
                  !isFranchisLogin()?<th scope="col">Phone Number</th>:""
                }
               
                
                
                <th scope="col">D.Location</th>
                <th scope="col">Coupon</th>

                <th scope="col">Status</th>
                <th scope="col">D Boy</th>
              </tr>
            </thead>

            <tbody>
              {isLoading ? (
                <>Loading</>
              ) : filterList && filterList.length > 0 ? (
                filterList.map((obj, i) => {
                  return <OrderItem item={obj} sno={i+1}/>;
                })
              ) : (
                <>No data</>
              )}
            </tbody>
            {consoleLog("result...", resultList)}
            {consoleLog("filter...", filterList)}
            {consoleLog("from", fromDate)}
            {consoleLog("to", toDate)}
            {consoleLog(err)}
          </table>
        </div>
      </div>
    </>
  );
}

export default UniqueStoreOrders;
