import { consoleLog } from '../../../utils/log-utils';
import {productImageUplaodRequest} from '../../service/ImageUploadService';

export const productImageRequest = (requestBody) => async (dispatch) => {
  const response = await dispatch(productImageUplaodRequest(requestBody));
  consoleLog("",response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    consoleLog("",response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};
