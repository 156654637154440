import axios from "axios";
import { useEffect, useState } from "react";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { DASHBOARD_API_PATH } from "../../network/config/apiPaths";
import { useNavigate } from "react-router-dom";
import { consoleLog } from "../../utils/log-utils";

function FODashboard() {
  const [result, setResult] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    dashboardApi();
  }, []);
  const dashboardApi = async () => {
    //let data =
    await axios
      .get(
        API_CONFIG.API_HOST +
          DASHBOARD_API_PATH.LIST_PATH +
          `?foId=${localStorage.getItem("userId")}`,
        {
          headers: API_HEADERS,
        }
      )
      .then((data) => {
        consoleLog("data", data);
        setResult(data.data.result);
      })
      .catch((err) => {
        consoleLog("err", err);
      });
  };
  return (
    <>
      {result && (
        <main className="container-fluid dashboard">
          <div className="row m-3">
            {/* <div className="col-6 col-md-4 col-lg-3">
              <div className="dashboard-div d-flex flex-column justify-content-center">
                Franchise Owner Amount <br /> {result.totalAppAmount} INR
              </div>
            </div> */}
            <div className="col-6 col-md-4 col-lg-3" onClick={()=>{navigate("/paymentsOverview")}}>
              <div className="dashboard-div1 d-flex flex-column justify-content-center">
                Total Amount <br /> <span style={{fontSize:20}}> ₹{result.foPaymentTotal}</span>
              </div>
            </div>

            {/* </div> */}
            <div className="col-6 col-md-4 col-lg-3" onClick={()=>{navigate("/franchiseSettlements")}}>
              <div className="dashboard-div1 d-flex flex-column justify-content-center">
                Settled Amount <br /> <span style={{fontSize:20}}>₹0 </span>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3" onClick={()=>{navigate("/paymentsOverview")}}>
              <div className="dashboard-div1 d-flex flex-column justify-content-center">
                Pending Amount <br /> <span style={{fontSize:20}}>₹{result.foPaymentTotal} </span>
              </div>
            </div>
            {/* <div className="row justify-content-around tble"> */}
            <div
              className="col-6 col-md-4 col-lg-3"
              onClick={() => navigate("/orders")}
            >
              <div className="dashboard-div1 d-flex flex-column justify-content-center">
                Total Orders <br /> <span style={{fontSize:20}}>{result.totalOrders} Nos</span>
              </div>
            </div>
            <div
              className="col-6 col-md-4 col-lg-3"
              onClick={() => navigate("/location")}
            >
              <div className="dashboard-div1 d-flex flex-column justify-content-center">
                Total Locations <br /> <span style={{fontSize:20}}>{result.totalLocations} Nos</span>
              </div>
            </div>

            <div
              className="col-6 col-md-4 col-lg-3"
              onClick={() => navigate("/stores")}
            >
              <div className="dashboard-div1 d-flex flex-column justify-content-center">
                Total Stores <br /> <span style={{fontSize:20}}>{result.totalStoresCount} Nos</span>
              </div>
            </div>
            <div
              className="col-6 col-md-4 col-lg-3"
              onClick={() => navigate("/team")}
            >
              <div className="dashboard-div1 d-flex flex-column justify-content-center">
                Total Employees <br /> <span style={{fontSize:20}}>{result.totalEmployees} Nos</span>
              </div>
            </div>

            <div
              className="col-6 col-md-4 col-lg-3"
              onClick={() => navigate("/customers")}
            >
              <div className="dashboard-div1 d-flex flex-column justify-content-center">
                Total Customers <br /> <span style={{fontSize:20}}>{result.totalCustomers} Nos</span>
              </div>
            </div>

            {/* <div className="col-6 col-md-4 col-lg-3" onClick={()=>navigate("/franchisePayments")}>
            
              <div className="dashboard-div1 d-flex flex-column justify-content-center">
                Total Payment <br /> {result.totalPayments} INR
              </div>
            </div> */}
          </div>
        </main>
      )}
    </>
  );
}

export default FODashboard;
