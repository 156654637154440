import React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export const useToastMsg=(msg1,msg2,fun) =>{
    const navigate=useNavigate();
    const successValue=()=>{
        toast.success(msg1)
        fun()
       
    }
    const warningValue=()=>{
        toast.warning(msg2)
    }
   return [successValue,warningValue]
}
export const useToastMsgWithOutFun=(msg1,msg2) =>{
    const navigate=useNavigate();
    const successValue=()=>{
        toast.success(msg1)
        // navigate(-1)
        
       
    }
    const warningValue=()=>{
        toast.warning(msg2)
    }
   return [successValue,warningValue]
}

