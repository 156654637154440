import { useEffect, useState } from "react";

import axios from "axios";

import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { PAYMENTS_API_PATH } from "../../../network/config/apiPaths";
import FranchisePaymentItem from "./franchise-payements-item";
import { consoleLog } from "../../../utils/log-utils";
import { useLocation } from "react-router-dom";
import { axiosApi } from "../../../network/service/config/AaxiosUtil";
import * as XLSX from "xlsx";

function FranchisePayments() {
  const [selectMonth, setMonth] = useState("");
  const [paymentList, setPaymentList] = useState([]);
  const location = useLocation();
  const storeId = location.state !== null ? location.state.storeId : "";
  const [filterForm, setFilterForm] = useState({
    startDate: "",
    endDate: "",
  });
  const month = [
    { id: 1, name: "JAN" },
    { id: 2, name: "FEB" },
    { id: 3, name: "MARCH" },
    { id: 4, name: "APRIL" },
    { id: 5, name: "MAY" },
    { id: 6, name: "JUNE" },
    { id: 7, name: "JULY" },
    { id: 8, name: "AUG" },
    { id: 9, name: "SEP" },
    { id: 10, name: "OCT" },
    { id: 11, name: "NOV" },
    { id: 12, name: "DEC" },
  ];

  useEffect(() => {
    paymentApiCall();
  }, [filterForm]);

  const paymentApiCall = async () => {
    let data = await axios.get(
      API_CONFIG.API_HOST +
        PAYMENTS_API_PATH.LIST_PATH +
        `?foId=${localStorage.getItem(
          "userId"
        )}&type=fo&storeId=${storeId}&fromDate=${filterForm.startDate}&toDate=${
          filterForm.endDate
        }`,
      {
        headers: API_HEADERS,
      }
    );
    consoleLog("data", data.data.result);
    setPaymentList(data.data.result);
  };

  const handleChange = (e) => {
    consoleLog("name", e.target.name);
    consoleLog("value", e.target.value);
    setFilterForm({
      ...filterForm,
      [e.target.name]: e.target.value,
    });
    consoleLog("filterForm", filterForm);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "data.csv";
    axiosApi
      .get(
        API_CONFIG.API_HOST +
          PAYMENTS_API_PATH.DOWNLOAD_PATH +
          `?foId=${localStorage.getItem(
            "userId"
          )}&type=fo&storeId=${storeId}&fromDate=${
            filterForm.startDate
          }&toDate=${filterForm.endDate}`,

        API_HEADERS
      )
      .then((res) => {
        const worksheet = XLSX.utils.json_to_sheet(res.data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "DataSheet.xlsx");
        // consoleLog("res.data", res.data);
        // link.href = URL.createObjectURL(
        //   new Blob([res.data], { type: "text/csv" })
        // );
        // link.click();
      });
  };

  return (
    <>
      <main className="container-fluid dashboard">
        <div className="row m-3">
          {/* <div className="col-6 col-md-4 col-lg-3">
              <div className="dashboard-div m-0 d-flex flex-column justify-content-center">
                Total Amount <br /> 10000 INR
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3">
              <div className="dashboard-div d-flex flex-column justify-content-center">
                Settled Amount <br /> 5000 INR
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3">
              <div className="dashboard-div d-flex flex-column justify-content-center">
                Pending Amount <br /> 5000 INR
              </div>
            </div> */}

          <div className="row justify-content-around tble mt-3">
            <div className="col-6">
              <h3 className="dashboard-title">Payments</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              {/* <button
                type="button"
                className="mr-auto btn btn-purple-bg"
                onClick={() => {
                  dispatch(
                    updateFormInfo({
                      displayForm: true,
                      formData: {},
                    })
                  );
                }}
              >
                + Add Category
              </button> */}
            </div>
          </div>
        </div>
        <div className="row m-3">
          {/* <div className="col-4 col-md-2">
            <label>Month</label>
            <select value={selectMonth} className="form-control">
              <option>{"< FEB >   (current year)"}</option>
              {month.map((item) => {
                return <option value={item.id}>{item.name}</option>;
              })}
            </select>
          </div> */}
          <div className="col-4 col-md-2">
            <label>From</label>
            <input
              type="date"
              name="startDate"
              value={filterForm.startDate}
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="col-4 col-md-2">
            <label>To</label>
            <input
              name="endDate"
              value={filterForm.endDate}
              type="date"
              onChange={handleChange}
              className="form-control"
            />
          </div>
          {/* <div className="col-4 col-md-2 top-margin">
            <label>Location</label>
           <select className="form-control">
            <option>{"--select city--"}</option>
           </select>
          </div> */}
          <div className="col-4 top-margin" style={{ marginLeft: "auto" }}>
            <label></label>
            <br />
            <div className="d-flex flex-row justify-content-end">
              <button
                onClick={() => handleDownload()}
                className="btn btn-success"
              >
                Download Excel
              </button>
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-12 card-1 h-scroll">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  {/* <th scope="col">Order Id</th> */}

                  {/* <th scope="col">Settlement ID</th> */}
                  <th scope="col">Date</th>
                  {/* <th Scope="col">Store Name</th> */}
                  {/* <th scope="col">Total Orders</th> */}
                  <th scope="col">Order Amount</th>
                  <th scope="col">Store Amount</th>

                  <th scope="col">App Share</th>
                  <th scope="col">Delivery Charges</th>
                  <th scope="col">Package Charges</th>
                  <th scope="col">Platform Fee</th>
                  <th scope="col">Coupon Cost</th>
                  <th scope="col">Bal. App Amount</th>
                  <th scope="col">FO Amount</th>

                  {/* <th scope="col">Date</th>
                  <th scope="col">Transaction ID</th> */}
                </tr>
              </thead>

              <tbody>
                {paymentList !== null ? (
                  paymentList.length > 0 &&
                  paymentList.map((item, i) => {
                    return (
                      <>
                        <FranchisePaymentItem item={item} key=
                        {item.id} sno={i} />
                      </>
                    );
                  })
                ) : (
                  <>No data found</>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </>
  );
}

export default FranchisePayments;
