import { createSlice } from "@reduxjs/toolkit";

export const toastSlice = createSlice({
  name: "toastMsg",
  initialState: {
    msgInfo: {
      display: false,
      message: "",
    },

    fcmMsgInfo: {
      display: false,
      title: "",
      message: "",
    },
  },
  reducers: {
    updateMessage: (state, data) => {
      state.msgInfo.display = data.payload.display;
      state.msgInfo.message = data.payload.message;
    },

    updateFcmMessage: (state, data) => {
      state.fcmMsgInfo.display = data.payload.display;
      state.fcmMsgInfo.title = data.payload.title;
      state.fcmMsgInfo.message = data.payload.message;
    },
  },
});

export const { updateMessage, updateFcmMessage } = toastSlice.actions;

export const toastStatus = (state) => state.rootReducer.toastReducer.msgInfo;

export const fcmToastStatus = (state) =>
  state.rootReducer.toastReducer.fcmMsgInfo;

export default toastSlice.reducer;
