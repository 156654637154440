import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { useLocation } from "react-router-dom";
import FranchiseDetails from "./franchise-details";
import FoPayments from "./franchise-payment";
import { consoleLog } from "../../../utils/log-utils";

function UniqueFranchise(){
    const location=useLocation();

    const foId=location.state!==null?location.state.name.id:""
    const event=location.state!==null?location.state.event:"Stores"
    const data=location.state!==null?location.state.name:""
    consoleLog("data",data)
    return(
        <>
        <div className="container-fluid dashboard">
        <div className="row m-3">
        <div className="col-6">
              <h3 className="dashboard-title">{data.name}</h3>
              <h6 className="dashboard-title">Phone: {data.phone}</h6>
              <h6 className="dashboard-title">Email: {data.email}</h6>
              <h6 className="dashboard-title">City: {data.city}</h6>
              <h6 className="dashboard-title">Radius : {data.radius}km</h6>
            </div>
          <div className="col-12 mt-3">
            <Tabs
              defaultActiveKey={event}
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="foStores" title="Stores">
                <FranchiseDetails id={foId} />
              </Tab>
              <Tab eventKey="foPayments" title="Payments">
                <FoPayments id={foId} />
              </Tab>
              
            </Tabs>
          </div>
          </div>
          </div>
        </>
    )

}
export default UniqueFranchise;