import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { displayRemoveAlert, formUpdateData, itemType } from '../../../redux/slice/formUpdateSlice';
import { BANNER_API_PATH, CATGORY_API_PATH, COUPONS_API_PATH, PRODUCT_API_PATH, STORE_API_PATH, SUB_CATGORY_API_PATH, TAG_API_PATH, UOM_API_PATH, USER_API_PATH } from '../../../network/config/apiPaths';
import axios from 'axios';
import { API_CONFIG, API_HEADERS } from '../../../network/config/ApiConfig';
import { consoleLog } from '../../../utils/log-utils';

function DeleteBanner(props) {
    const dispatch=useDispatch();
   
    const formData = useSelector(formUpdateData);
    const optionType = useSelector(itemType);
    consoleLog("form data",formData)
    const itemInfo = formData.item;

   
   consoleLog("item info..",itemInfo)
    const deleteItem=async ()=>{
      let path="";
     
      switch(optionType){
        case "banner":
          path=BANNER_API_PATH.DELETE_BANNER
          break
        case "coupon":
          path=COUPONS_API_PATH.DELETE_COUPON
          break
        case "users":
          path=USER_API_PATH.DELETE_USER
          break
        case "tags":
          path=TAG_API_PATH.TAG_DELETE
          break
        case "store":
          path=STORE_API_PATH.DELETE_STORE
          break
        case "category":
            path=CATGORY_API_PATH.DELETE_CATEGORY
            break
        case "subCategory":
            path=SUB_CATGORY_API_PATH.DELETE_SUB_CATEGORY
            break
        case "uom":
          path=UOM_API_PATH.DELETE_UOM
          break
        case "imageDelete":
          path=PRODUCT_API_PATH.DELETE_IMAGE
          break
        
          }

          // if(optionType==="imageDelete"){
          //   consoleLog("delete path",formData)
          // }
          // else{
            await axios.delete(API_CONFIG.API_HOST+path+itemInfo.id,{
              headers:API_HEADERS
            }).then(response=>{
                 
                 consoleLog("response",response)
                 dispatch(displayRemoveAlert({ displayRemove: false, formData: null }))
                 if (props.onSubmit) props.onSubmit();
            })
          
       consoleLog("delete path",API_CONFIG.API_HOST+path+itemInfo.id)
          // }
      
    }
    return (
        <>
          {/* {isLoading && <AppLodingBar />} */}
          <div
            style={{
              position: "fixed",
              backgroundColor: "rgba(0,0,0,0.5)",
              width: "100%",
              height: "100%",
              zIndex: "999",
              overflow: "scroll",
            
            }}
          >
            <div className="d-flex align-item-center justify-content-center">
              <div className="col-md-8">
                <div className="container">
                  <div className="row mt-5">
                    <div className="col-md-5 card-1 p-5">
                     
                        <h4 className="text-center">Delete {optionType}</h4>
                        <p className="text-center">Are you sure you want to delete {itemInfo.name}</p>
                     
                      <div className="col-md-12">
                        <div className="form-group">
    
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 20,
                            }}
                          >
                            <button
                              style={{ display: "flex", justifyContent: "center" }}
                              type="button"
                              className="btn btn-purple-bg"
                              onClick={deleteItem}
                            >
                              Ok
                            </button>
                            <button
                              className="btn btn-white-bg"
                              onClick={()=> dispatch(displayRemoveAlert({ displayRemove: false, formData: null }))}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
}

export default DeleteBanner