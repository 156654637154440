import React, { useEffect, useState } from 'react'
import ProductItem from './unique store items/Product-Item'
import axios from 'axios';
import { API_CONFIG, API_HEADERS } from '../../../network/config/ApiConfig';
import { STORE_API_PATH } from '../../../network/config/apiPaths';
import { isCustomerSupport, isFranchisLogin } from '../../../utils/userRoles';
import { consoleLog } from '../../../utils/log-utils';

function UniqueStoreProducts(props) {

  const [productsList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [searchValue,setSearchValue] = useState("");
  const [filterList,setFilterList] = useState([])

  useEffect(() => {
    consoleLog("id..", props.id)
    StoreProductsApi()

  }, [])

  const StoreProductsApi = async () => {
    setIsLoading(true)
    let data = await axios.get(API_CONFIG.API_HOST + STORE_API_PATH.UNIQUE_STORE_PRODUCTS + `storeId=${props.id}`, {
      headers: API_HEADERS
    })
    consoleLog(data.data)
    setIsLoading(false)
    if (data.data.result !== null){
      setProductList(data.data.result);
      setFilterList(data.data.result)}
  }

  const searchResult=(e)=>{
      setSearchValue(e.target.value)
      let term=e.target.value;
      let data=productsList.filter(item=>{
        if(item.product_name.toLowerCase().includes(term.toLowerCase())){
          return item
        }
      })
      setFilterList(data)
  }

  return (
    <>
    {/* <div className="row  mb-3">
          <div className="col-2">
            <div className="dashboard-div d-flex flex-column justify-content-center">
                   100 <br/> Orders Recieved
            </div>
          </div>
          <div className="col-2">
            <div className="dashboard-div d-flex flex-column justify-content-center">
            100 <br/> Orders Accepted
            </div>
          </div>
          <div className="col-2">
            <div className="dashboard-div d-flex flex-column justify-content-center">
            50 <br/> Orders Shipped
            </div>
          </div>
          <div className="col-2">
            <div className="dashboard-div d-flex flex-column justify-content-center">
            30 <br/> Orders Delivered
            </div>
          </div>
          <div className="col-2">
            <div className="dashboard-div d-flex flex-column justify-content-center">
            0 <br/> Orders Cancelled
            </div>
          </div>
        </div> */}
      

      <div className="row">
        <div className='col-12 col-lg-3 mb-3'>
          <input className='form-control' placeholder='search by product name' onChange={searchResult}/>
        </div>

        <div className="col-md-12 card-1 h-scroll">
          <table className="table table-hover align-middle">
            <thead className="thead-dark">
              <tr id="Row1">
                <th scope="col">ID</th>
                <th Scope="col">Category</th>
                <th scope="col">Product Image</th>

                <th scope="col">Product Name</th>
                <th scope="col">QTY</th>
                <th scope="col">UOM</th>
                <th scope="col">Sale Price</th>
                <th scope="col">Offer Price</th>
                <th scope="col" colSpan={2}>GST</th>
                <th scope="col">APP%</th>
                <th scope="col">Rating</th>
                {(!isFranchisLogin()&& !isCustomerSupport())&& <>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </>}

              </tr>
              <tr id="Row2">
                <td></td>
                <td></td>
                <td></td>

                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <th scope="col">CGST%</th>
                <th scope="col">SGST%</th>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </thead>

            <tbody>
              {
                isLoading ? (
                  <>Loading</>
                ) :
                  filterList && filterList.length > 0 ? filterList.map((obj, i) => {
                    return (
                      <ProductItem item={obj} sno={i+1} />
                    )
                  }) : (
                    <>No data</>
                  )

              }
            </tbody>

          </table>
        </div>
      </div>

    </>
  )
}

export default UniqueStoreProducts