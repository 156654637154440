import React, { useEffect, useState } from 'react'



import axios from 'axios';
import FoPaymentItem from './fo-payment-item';
import { API_CONFIG, API_HEADERS } from '../../network/config/ApiConfig';
import { LOCATION_API_PATH, PAYMENTS_API_PATH, USER_API_PATH } from '../../network/config/apiPaths';
import { consoleLog } from '../../utils/log-utils';
import { useLocation } from 'react-router-dom';

function FoPayment(props) {
  const[isLoading,setIsLoading]=useState(false)
  const[paymentList,setPaymentList]=useState([]);
  const[selectMonth,setMonth]=useState("");
  const[filterForm,setFilterForm]=useState({
    fo_name:"",
  })
  
  const location=useLocation();
  const storeId=location.state!=null?location.state.storeId:"";
  const foId=location.state!=null?location.state.foId:"";
  const foName=location.state!=null?location.state.foName:"";
  const storeName=location.state!=null?location.state.storeName:"";

  const month=[
    {id:1,name:"JAN"},
    {id:2,name:"FEB"},
    {id:3,name:"MARCH"},
    {id:4,name:"APRIL"},
    {id:5,name:"MAY"},
    {id:6,name:"JUNE"},
    {id:7,name:"JULY"},
    {id:8,name:"AUG"},
    {id:9,name:"SEP"},
    {id:10,name:"OCT"},
    {id:11,name:"NOV"},
    {id:12,name:"DEC"},
  ]

  useEffect(()=>{
    paymentApiCall();
    
    
  },[])
  
  const paymentApiCall = async () => {
    let data = await axios.get(
      // API_CONFIG.API_HOST +
      //   PAYMENTS_API_PATH.ADMIN_FO_PAYMENTS+

      //   `?storeId=${storeId}&type=fo`,

        API_CONFIG.API_HOST +
        PAYMENTS_API_PATH.LIST_PATH +
        `?foId=${foId}&type=fo&storeId=${storeId}`,
     
      {
        headers: API_HEADERS,
      }
    );
    consoleLog("data", data.data.result);
    setPaymentList(data.data.result);
  };
 
  const handleChanges=(e)=>{
  setFilterForm({
  ...filterForm,
  [e.target.name]:e.target.value
})
  }

  

  
  return (
    <>
     {/* <div className='row mb-3'>
        <div className='col-2'>
        <label>Month</label>
          <select value={selectMonth} className='form-control'>
            <option>{"--select month--"}</option>
            {
              month.map(item=>{
                return(
                  <option value={item.id}>{item.name}</option>
                )
              })
            }
          </select>
        </div>
        <div className='col-2'>

          <label>From</label>
          <input type="date" className='form-control'/>
            
          
        </div>
        <div className='col-2'>

          <label>To</label>
          <input type="date" className='form-control'/>
            
          
        </div>
       </div> */}
    {/* <div className="row mb-3 ">
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
             100 <br/> Orders Recieved
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      100 <br/> Orders Accepted
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      50 <br/> Orders Shipped
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      30 <br/> Orders Delivered
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      0 <br/> Orders Cancelled
      </div>
    </div>
  </div> */}
  <main className="container-fluid dashboard">
    <div className='row m-3'>
      <div className='col-12 mb-3'>
         <h5>Fo Name : {foName}</h5>
         <h5>Store Name : {storeName}</h5>
      </div>
      
    <div className="col-12 card-1 h-scroll">
    <table className="table table-hover align-middle">
      <thead className="thead-dark">
        <tr>
        <th scope="col">Date</th>
        {/* <th scope="col">FO Name</th> */}
        
          
          <th scope="col">Order Id</th>
          <th scope="col">Order Amount</th>
          {/* <th scope="col">Total Orders</th> */}
          <th scope="col">Store Payment</th>
          <th scope="col">App Share</th>
          <th scope="col">Delivery Charges</th>
          <th scope="col">Package Charges</th>
          <th scope="col">Platform Fee</th>
          <th scope="col">Coupon Cost</th>
          
          <th scope="col">FO Amount</th>
          <th scope="col">Final App Share</th>
        </tr>
      </thead>
      
        <tbody>
          
          {
                  paymentList !==null ? paymentList.length>0&&
                  paymentList.map((item,i)=>{
                    return(
                      <>
                      <FoPaymentItem item={item} sno={i+1}/>
                      </>
                    )
                  }):<>No data found</>
                  }
        </tbody>
      
    </table>
  </div>
  </div>
  </main>
  </>
  )
}

export default FoPayment