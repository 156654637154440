import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { formatedDate } from "../../../utils/date-utils";

function FoPaymentItem(props){
    const sno=props.sno
    const [item]=useState(props.item)
    const navigate=useNavigate()

    function Temp(status) {
        switch (status) {
          case "0":
            return <span style={{ color: "black" }}>Pending</span>;
    
          case "1":
            return <span style={{ color: "pinkAccent" }}>Accepted</span>;
    
          case "2":
            return <span style={{ color: "blueAccent" }}>Assigned</span>;
    
          case "3":
            return <span style={{ color: "yellow" }}>Picked Up</span>;
          case "4":
            return <span style={{ color: "green" }}>Delivered</span>;
          case "5":
            return <span style={{ color: "green" }}>Delivered</span>;
          case "6":
            return <span style={{ color: "red" }}>Cancelled</span>;
          case "7":
            return <span style={{ color: "black" }}>Rejected</span>;
          case "8":
            return (
              <span style={{ color: "0xffcc0d0d" }}>Order Cancel Request</span>
            );
        }
      }
      const orderDetailFynctionality = () => {
        //dispatch(updateFormInfo({ displayForm: false, formData: { order } }));
    
        navigate("/orderDetails", { state: { order:{id:item.order_id}, from: "fo" } });
      };

      const storeDetails=()=>{
        navigate("/uniqueStore",{state:{storeId:item.storeId,storeName:item.storeName,event:"orderWisePayments"}})
      }
    return(
        <>
        <tr>
            <td>{sno}</td>
            <td>{formatedDate(item.dated)}</td>
            <td onClick={orderDetailFynctionality}><Link>#EPo{item.order_id}</Link></td>
            <td>{item.orderPayment}</td>
            <td onClick={storeDetails}><Link>{
        item.storeName}</Link></td>
            <td>{item.customerName}</td>
            <td>{item.address}</td>
            <td>{Temp(item.orderStatus)}</td>
            <td>{item.paymentType}</td>
            
        </tr>
        </>
    )
}

export default FoPaymentItem;