import React, { useEffect, useState } from "react";

import axios from "axios";

import { useNavigate } from "react-router-dom";
import CustomerItem from "./customer-item";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { USER_API_PATH } from "../../../network/config/apiPaths";
import { consoleLog } from "../../../utils/log-utils";
import { isAdmin, isFranchisLogin } from "../../../utils/userRoles";


function Customer() {
  const navigate=useNavigate();
  const [apiResult, setApiResult] = useState([]);

  const [searchVal, setSearchVal] = useState("");
 
  const [filterList, setFilterList] = useState([]);

  const [isLoading,setIsLoading] = useState(false)

  

  useEffect(() => {
    UserList();
  }, []);

  const UserList = async () => {
    setIsLoading(true)
    const data = await axios.get(
      API_CONFIG.API_HOST + USER_API_PATH.LIST_PATH+"5",
      {
        headers: API_HEADERS,
      }
    );
    setIsLoading(false)
    if(data.data.result !== null){
    setApiResult(data.data.result);
    setFilterList(data.data.result)
    }
  };

  

  function handleSearchClick(e) {
    const searchTerm = e.target.value;
    setSearchVal(searchTerm);
    consoleLog("search Value", searchTerm);
      const filterBySearch = apiResult.filter((item) => {
        if (item.phone.includes(searchTerm)) {
          return item;
        }
      });
      setFilterList(filterBySearch);
   
  }

  apiResult.length === 0 && <span>Loading</span>;
  {consoleLog("apiResult",apiResult)}
  return (
   
    <main className="container-fluid dashboard">
      <div className="row m-3">
        <div className="row justify-content-around tble">
          <div className="col-6">
            <h3 className="dashboard-title">Customers</h3>
          </div>
          <div className="mr-auto col-6 text-end">
           
          </div>
        </div>
      </div>
      <div className="row m-3">
        <div className="col-12 col-md-3">
          <div className="form-group">
            <label>Search</label>
            <input
              type="number"
              name="name"
              className="form-control"
              value={searchVal}
              onChange={handleSearchClick}
              placeholder="Enter Phone No"
            />
          </div>
          
        </div>
        <div className="col-12 col-md-3">
          {/* <div className="form-group">
            <label>Location Search</label>
            <input
              type="text"
              name="search"
              className="form-control"
              value={searchVal}
              onChange={handleSearchClick}
              placeholder="Enter Location"
            />
          </div> */}
          
        </div>
        <div className="col-12 col-md-3">
          {/* <div className="form-group">
            <label>Area Search</label>
            <input
              type="search"
              name="name"
              className="form-control"
              value={searchVal}
              onChange={handleSearchClick}
              placeholder="Enter Area"
            />
          </div> */}
          
        </div>
        <div className="col-12 card-1 h-scroll">
          <table className="table table-hover align-middle">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Sno</th>
                <th scope="col">Name</th>
                {
                  isFranchisLogin()?"":<th scope="col">Contact No</th>
                }
                
                {/* <th scope="col">Area</th> */}
                <th scope="col">Type</th>
                <th scope="col">Device</th>
               
                
                {/* <th scope="col">status</th> */}
               
              </tr>
            </thead>

            <tbody>
              {
                isLoading ? (
                  <>Loading</>
                ):
                filterList && filterList.length>0 ?
              filterList.map((obj) => {
                return <CustomerItem {...obj} key={obj.id} />;
              }):(<>No data</>)

              }
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
}

export default Customer;