import axios from "axios"
import { useEffect, useState } from "react"
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig"
import { USER_API_PATH } from "../../../network/config/apiPaths"
import { consoleLog } from "../../../utils/log-utils"
import CustomerAddressItem from "./customer-address-item"

function CustomerAddress(props){
    const [address,setAddress]=useState([])
    
    useEffect(()=>{
        fetchAddress()
    },[])

    const fetchAddress=async()=>{
      let data=await axios.get(API_CONFIG.API_HOST+USER_API_PATH.USER_ADDRESS+props.id,{
        headers:API_HEADERS
      })
      consoleLog("Address",data.data.result)
      setAddress(data.data.result)
    }
  return(
    <>
     <main className="container-fluid dashboard">
        <div className="row">
       
          <div className="col-12 card-1 h-scroll">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Sno</th>

                  <th scope="col">Area</th>
                  {/* <th scope="col">Date</th> */}
                  <th scope="col">Location</th>
                  <th scope="col">Type</th>
                  <th scope="col">Pin Code</th>
                  
                </tr>
              </thead>

              <tbody>
                {address !== null ? (
                  address.length > 0 &&
                  address.map((item, i) => {
                    return (
                      <>
                        <CustomerAddressItem item={item} sno={i+1} />
                      </>
                    );
                  })
                ) : (
                  <>No data found</>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </main>

    </>
  )
}
export default CustomerAddress