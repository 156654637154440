import { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { displayRemoveAlert } from "../../../redux/slice/formUpdateSlice";

function StoreAdminItem(props) {
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const [product]=useState(props.item)
  const deleteItem = () =>{
    dispatch(displayRemoveAlert({ displayRemove: true, formData: { item:product },type:"users" }));
  }
  const storeDetails=()=>{
    navigate("/uniqueStore",{state:{storeId:product.orgId,storeName:product.orgName}})
  }
  const editItem=()=>{
    navigate("/userEdit",{state:{item:product}})
  }

  // const onStatusChange = async () => {
  //   let status = "YES";
  //   if (product.status == "NO") {
  //     status = "YES";
  //   } else {
  //     status = "NO";
  //   }

  //   let requestBody = {
  //     status: status,
  //     id: product.id,
  //   };

  //   await axiosApi
  //     .post(API_CONFIG.API_HOST + STORE_API_PATH.CREATE_STORE, requestBody, {
  //       API_HEADERS,
  //     })
  //     .then((response) => {
  //       consoleLog("STORE UPODATE", response);
  //       if (response.status === 200 && response.data.status === true) {
  //         setItem(product, (product.status = status));
  //         setitemStatus(status);
  //       } else {
  //         dispatch(
  //           updateMessage({
  //             display: true,
  //             message: "Error While updating info",
  //           })
  //         );
  //       }
  //     });

  //   // await dispatch(createUpdateAction(requestBody)).then((response) => {
  //   //   if (response.status == true) {
  //   //     setItem(product, (product.status = status));
  //   //     setitemStatus(status);
  //   //   } else {
  //   //     dispatch(
  //   //       updateMessage({
  //   //         display: true,
  //   //         message: "Error While updating info",
  //   //       })
  //   //     );
  //   //   }
  //   // });
  // };

  return (
    <>
      <tr>
        <td> {product.id} </td>
        <td>
          {product.name}
        </td>
        <td> {product.phone} </td>
        <td onClick={storeDetails}><Link className="anchor-color-change"> {product.orgName}</Link> </td>
        
        {/* <td className="align-middle">
            <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
                <input
                    class="form-check-input"
                    type="checkbox"s
                    role="switch"
                    checked={product.status === "ACTIVE" ? true : false}

                />
            </div>
        </td> */}
        <td>{product.city}</td>
        <td className="align-middle">
                    <div className="d-flex justify-content-center">
                    <span className="edit-icon-btn" onClick={editItem}>
                        <FaEdit/>
                    </span>
                    {/* <span className="delete-icon-btn" onClick={deleteItem}>
                        <FaTrash/>
                        </span> */}
                    </div>
                </td>
      </tr>
    </>
  );
}

export default StoreAdminItem;
