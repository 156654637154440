import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {todayDateDisplay} from '../../utils/date-utils'
import { consoleLog } from "../../utils/log-utils";

function SettlementPopUp(){
    const navigate=useNavigate();
    const currentDate=todayDateDisplay()
    const [settledDetails,setSettledDetails]=useState({
        sId:"",
        sDate:currentDate,
    })
   const handleChange=(e)=>{
        setSettledDetails(
            {
                ...setSettledDetails,
                [e.target.name]:e.target.value
            }
        )
   }
    return (
        <>
        {consoleLog("settlement details",setSettledDetails)}
          <div
            style={{
              position: "fixed",
              backgroundColor: "rgba(0,0,0,0.5)",
              width: "100%",
              height: "100%",
              zIndex: "999",
              overflow: "scroll",
            }}
          >
            <div className="align-item-center d-flex  flex-row justify-content-center w-100">
              <div className="col-md-8">
                <div className="container">
                  <div className="row mt-5 w-100">
                    <div className="col-md-5 card-1 p-5">
                      {/* {itemInfo != undefined ? (
                        <h4 className="text-center">Settlement</h4>
                      ) : ( */}
                        <h4 className="text-center">Settlement</h4>
                      {/* )} */}
    
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Transaction Id</label>
                          <input
                            type="text"
                            name="sId"
                            className="form-control"
                            value={settledDetails.sId}
                            onChange={handleChange}
                          />
                          <label>Transaction Date</label>
                          <input
                            type="date"
                            name="sDate"
                            className="form-control"
                            value={settledDetails.sDate}
                            onChange={handleChange}
                          />
    
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 20,
                            }}
                          >
                            <button
                              style={{ display: "flex", justifyContent: "center" }}
                              type="button"
                              className="btn btn-purple-bg"
                              
                            >
                              Submit
                            </button>
                            <button
                              className="btn btn-white-bg"
                              onClick={()=>{navigate(-1)}}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
}
export default SettlementPopUp