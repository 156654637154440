import { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { displayRemoveAlert } from "../../redux/slice/formUpdateSlice";
import { isFranchisLogin } from "../../utils/userRoles";

function UserList(props) {
  const navigate=useNavigate();
  const dispatch=useDispatch();
  const [product]=useState(props.item)
  const deleteItem = () =>{
    dispatch(displayRemoveAlert({ displayRemove: true, formData: { item:product },type:"users" }));
  }
  const editItem=()=>{
    navigate("/userEdit",{state:{item:product}})
  }
  return (
    <>
      <tr>
        <td> {product.id} </td>
        <td>
         {product.name}
        </td>
        <td> {product.phone}/{product.email} </td>
        <td> {product.roleName==="USER"?"CUSTOMER":product.roleName} </td>
        
        {/* <td className="align-middle">
            <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
                <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    checked={product.status === "ACTIVE" ? true : false}

                />
            </div>
        </td> */}
        {
          !isFranchisLogin()?<td className="align-middle">
          <div className="d-flex justify-content-center">
          <span className="edit-icon-btn" onClick={editItem}>
              <FaEdit/>
          </span>
          {/* <span className="delete-icon-btn" onClick={deleteItem}>
              <FaTrash/>
              </span> */}
          </div>
      </td>:""
        }
        
      </tr>
    </>
  );
}

export default UserList;
