import React, { useEffect, useState } from 'react'

import StorePaymentItem from './unique store items/Payment-Item'
import { API_CONFIG, API_HEADERS } from '../../../network/config/ApiConfig';
import { PAYMENTS_API_PATH } from '../../../network/config/apiPaths';
import axios from 'axios';
import { consoleLog } from '../../../utils/log-utils';
import { isFranchisLogin } from '../../../utils/userRoles';

function UniqueStorePayments(props) {
  const[isLoading,setIsLoading]=useState(false)
  const[paymentList,setPaymentList]=useState([]);
  const[selectMonth,setMonth]=useState("");
  const month=[
    {id:1,name:"JAN"},
    {id:2,name:"FEB"},
    {id:3,name:"MARCH"},
    {id:4,name:"APRIL"},
    {id:5,name:"MAY"},
    {id:6,name:"JUNE"},
    {id:7,name:"JULY"},
    {id:8,name:"AUG"},
    {id:9,name:"SEP"},
    {id:10,name:"OCT"},
    {id:11,name:"NOV"},
    {id:12,name:"DEC"},
  ]

  useEffect(()=>{
    paymentApiCall();
  },[])

  const paymentApiCall=async()=>{
     let data=await axios.get(API_CONFIG.API_HOST+PAYMENTS_API_PATH.LIST_PATH+`?storeId=${props.id}`,{
      headers:API_HEADERS
     })
     consoleLog("data",data.data.result)
     setPaymentList(data.data.result)
  }

  return (
    <>
     {/* <div className='row mb-3'>
        <div className='col-2'>
        <label>Month</label>
          <select value={selectMonth} className='form-control'>
            <option>{"--select month--"}</option>
            {
              month.map(item=>{
                return(
                  <option value={item.id}>{item.name}</option>
                )
              })
            }
          </select>
        </div>
        <div className='col-2'>

          <label>From</label>
          <input type="date" className='form-control'/>
            
          
        </div>
        <div className='col-2'>

          <label>To</label>
          <input type="date" className='form-control'/>
            
          
        </div>
       </div> */}
    {/* <div className="row mb-3 ">
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
             100 <br/> Orders Recieved
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      100 <br/> Orders Accepted
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      50 <br/> Orders Shipped
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      30 <br/> Orders Delivered
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      0 <br/> Orders Cancelled
      </div>
    </div>
  </div> */}
  <div className="container dashboard">
    <div className='row'>
    <div className="col-l2 card-1 h-scroll">
    <table className="table table-hover align-middle">
      <thead className="thead-dark">
        <tr>
          <th scope="col">Sno</th>
          
          {/* <th scope="col">Settlement ID</th> */}
          <th scope="col">Date</th>
          <th scope="col">Total Orders</th>
          <th scope="col">Total Amount</th>
          <th scope="col">Payment Status</th>
          <th scope="col">Transaction Date</th>
          {
            !isFranchisLogin()&&<th scope="col">Transaction ID</th>
          }
          
        </tr>
      </thead>
      
        <tbody>
          
          {
                  paymentList !==null ? paymentList.length>0&&
                  paymentList.map((item,i)=>{
                    return(
                      <>
                      <StorePaymentItem item={item} sno={i}/>
                      </>
                    )
                  }):<>No data found</>
                  }
        </tbody>
      
    </table>
  </div>
  </div>
  </div>
  </>
  )
}

export default UniqueStorePayments