import { useState } from "react";
import { useNavigate } from "react-router-dom";

function DbPaymentItem(props){
    const [data]=useState(props.item)
    const navigate=useNavigate();

    const dbSettlement=()=>{
        navigate("/settlementPopUp")
    }
    return(
        <>
        <tr>
        <td>{props.sno}</td>
        <td> {data.duration}</td>
            <td>{data.dbName}</td>
            
            <td>{data.ordersCount}</td>
            <td>{data.orderPayment}</td>
            <td>{data.dbCharges}</td>
            <td>{data.transaction_date }</td>
            <td>{data.transaction_id===""?"-----":data.transaction_id}</td>
            <td>{data.payment_status==="PENDING"?<button className="btn dashboard-div-bg" onClick={dbSettlement}>Update</button>:data.payment_status}</td>
        </tr>
        </>
    )
}
export default DbPaymentItem;