import { useLocation } from "react-router-dom"
import FranchiseDetailItem from "./franchise-detail-item"
import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { STORE_API_PATH } from "../../../network/config/apiPaths";
import { useEffect, useState } from "react";
import { consoleLog } from "../../../utils/log-utils";

function FranchiseDetails(){
    const location=useLocation();
    const item=location.state != null  ? location.state.name : ""
  
    const [stores,setStores]=useState([]);
    
    useEffect(()=>{
      getFoStores()
    },[])

    const getFoStores=async()=>{
      let data=await axios.get(API_CONFIG.API_HOST + STORE_API_PATH.STORE_LIST + `?frId=${item.id}`,{
        headers:API_HEADERS
      })
      consoleLog("data",data.data.result)
      setStores(data.data.result)
    }
    return(
        <>
         {/* <main className="container-fluid dashboard"> */}
      <div className="row">
        <div className="row justify-content-around tble">
          <div className="col-6">
            {/* <h3 className="dashboard-title">{item.name}</h3> */}
            {/*  */}
          </div>
          <div className="mr-auto col-6 text-end">
            {/* <button
              type="button"
              className="mr-auto btn btn-purple-bg"
             
            >
              + Add
            </button> */}
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-12 col-md-3">
          <div className="form-group">
            <label>Search</label>
            <input
              type="number"
              name="name"
              className="form-control"
             
              placeholder="Enter Phone No"
            />
          </div>
        </div>
        
        <div className="col-12 card-1 h-scroll">
          <table className="table table-hover align-middle">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Sno</th>
                <th scope="col">Category</th>
                <th scope="col">Store Name</th>
                <th scope="col">Contact no</th>
               
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>

            <tbody>
              {
                // isLoading ? (
                //   <>Loading</>
                // ):
                stores&&stores.length>0?
                stores.map((obj,i) => {
                  return(
                  <FranchiseDetailItem item={obj} sno={i+1}/>
                  )
              }):(
                <>No data</>
              )
            }
            
            </tbody>
          </table>
        </div>
      </div>
    {/* </main> */}
        </>
    )
}

export default FranchiseDetails