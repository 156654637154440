import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { isFranchisLogin } from '../../../../utils/userRoles';

function StorePaymentItem(props) {
  const [data]=useState(props.item)
  const navigate=useNavigate();
  return (
    <tr>
            
            <td>{props.sno}</td>
            {/* <td><Link to="/uniqueStore">{data.storeName}</Link></td> */}
            {/* <td>#Svr210524</td> */}
            <td>{data.dated}</td>
            <td>{data.orders_count}</td>
            <td>₹{data.orders_amount}</td>
            <td>
              {data.payment_status}
              </td>
            <td>{data.transaction_date}</td>
            {
              !isFranchisLogin()&&<td>{data.payment_status==="PENDING"?<button onClick={()=>navigate("/updateTransId")} className='btn btn-success'>Update</button>:data.transaction_id}</td>

            }
        
        </tr>
  )
}

export default StorePaymentItem