import { FaEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

function PaymentsFranchiseListItem(props) {
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const [product]=useState(props.item)
  
  const deliveryPayments=()=>{
    navigate("/paymentsOverview",{state:{fo:product}})
 }
  return (
    <>
      <tr>
        <td> {product.duration} </td>
        <td>{product.address}</td>
        <td onClick={deliveryPayments}>
          <Link className="anchor-color-change">{product.foName}</Link>
        </td>
        <td>₹{product.foPayment}</td>
        <td>₹{product.pendingAmount}</td>
        <td>₹{product.settledAmount}</td>
        <td>{product.transaction_date}</td>
        <td>{product.transaction_id}</td>
        <td>{product.status}</td>
        {/* <td> {product.phone} </td> */}
        
        
        {/* <td>{product.fo_share}</td> */}
        
        {/* <td className="align-middle">
            <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
                <input
                    class="form-check-input"
                    type="checkbox"s
                    role="switch"
                    checked={product.status === "ACTIVE" ? true : false}

                />
            </div>
        </td> */}
        
        
      </tr>
    </>
  );
}

export default PaymentsFranchiseListItem;
