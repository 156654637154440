import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { BANNER_API_PATH, CATGORY_API_PATH, STORE_API_PATH } from "../../network/config/apiPaths";
import AutoComplete from "react-google-autocomplete";
import axios from "axios";
import { useToastMsg, useToastMsgWithOutFun } from "../hooks/useToastMsg";
import { useDispatch, useSelector } from "react-redux";
import { formUpdateData } from "../../redux/slice/formUpdateSlice";
import { consoleLog } from "../../utils/log-utils";

function UpdateStore() {
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const location=useLocation()
  // const formData = useSelector(formUpdateData);
  const itemInfo = location.state.product;
  consoleLog("item info",itemInfo)

  const [inputValidation, setInputValidation] = useState({
    storeName: itemInfo.name,
    contactName: itemInfo.contact_name,
    contactNo: itemInfo.contact_one,
    selectCategory: itemInfo.section_id,
    location: itemInfo.landmark,
    gstNo: itemInfo.gst,
    deliveryCharge:itemInfo.delivery_charge,
    minFree:itemInfo.free_delivery_min,
    fssaiNo: itemInfo.fssai,
    radius: itemInfo.radius,
    deliveryTime:itemInfo.delivery_time,
    fo:itemInfo.franchise_id,
    
  });

  const[areaName,setAreaName] = useState(itemInfo.landmark);
  const[cityName,setCity]=useState("");
  const[latitude,setLatitude]=useState(itemInfo.latitude);
  const[longitude,setLongitude]=useState(itemInfo.longitude);
  const[categoryList,setCategoryList]=useState([]);
  const [foList,setFoList]=useState([])
  consoleLog(latitude,"latitude")
  consoleLog(longitude,"latitude")

  useEffect(()=>{
    CategoryApi()
  },[])
 useEffect(()=>{
  fetchFoList()
 },[latitude,longitude])
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValidation({
      ...inputValidation,
      [name]: value,
    });
  };

  
  const reset = () =>{
    setInputValidation({
      storeName: "",
    contactName: "",
    contactNo: "",
    selectCategory: "",
    location: "",
    gstNo: "",
    
    fssaiNo: "",
    radius: "",
    fo:"",
    minFree:"",
    deliveryTime:""
    });
    setAreaName("");
    setCity("");
    setCategoryList([]);
    setLatitude(0);
    setLongitude(0);
  }

  const[success,warning]=useToastMsgWithOutFun("successfully created","Required all input feilds")

  const Validation = () => {
    inputValidation.storeName === "" ||
    inputValidation.contactName === "" ||
    inputValidation.contactNo === "" ||
    inputValidation.selectCategory === "" ||
   areaName===""||
    inputValidation.radius === "" || inputValidation.deliveryCharge === "" || 
    inputValidation.deliveryTime === "" ||
    inputValidation.minFree=== "" || inputValidation.fo===""
      ? warning()
      : CreateStore();
  };

  async function fetchFoList() {
    if (latitude !== 0 && longitude!== 0) {
      let apiPath =
        API_CONFIG.API_HOST +
        `user/getNearbyUsers?lat=${latitude}&lng=${longitude}&roleId=9`;

      const data = await axios.get(apiPath, {
        headers: API_HEADERS,
      });

      // setIsLoading(false);
      consoleLog("FoResult", data.data.result);

      setFoList(data.data.result);
      // setResultList(data.data.result);
    }
  }

  const CreateStore=async ()=>{
    let response=await fetch(API_CONFIG.API_HOST+STORE_API_PATH.CREATE_STORE,{
      method:"POST",
      headers: API_HEADERS,
      body:JSON.stringify({
        landmark:areaName,
        name:inputValidation.storeName,
        cName:inputValidation.contactName,
       latitude:latitude,
       longitude:longitude,
       radius:inputValidation.radius,
       isVeg:"YES",
       city:areaName,
       fssai:inputValidation.fssaiNo,
       gstNumber:inputValidation.gstNo,
       address:"",
       phoneOne:inputValidation.contactNo,
       sectionId:inputValidation.selectCategory,
       createdBy:"",
       deliveryTime:inputValidation.deliveryTime,
       id:itemInfo.id,
       dCharge:inputValidation.deliveryCharge,
       free_delivery_min:inputValidation.minFree,
       franchiseId:inputValidation.fo,

      })
    })
    consoleLog("response",response)
    response.status===200 && success()
   
  }

  const updatePlaceInfo = (place) => {
    if (place && place.address_components != null) {
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        switch (addressType) {
          case "locality":
           setAreaName(place.address_components[i].long_name)
          

            
            break;
          case "administrative_area_level_3":
          setCity(place.address_components[i].long_name)
         
            
            
            break;
          // case "country":
          //   setCountry(place.address_components[i].long_name);
          //   break;
        }
      }
    }
    consoleLog("place..", place);
    
    setLatitude(place.geometry.location.lat())
    setLongitude(place.geometry.location.lng())
  };

  const labelStyle = {
    fontWeight: "bold",
  };

  const CategoryApi=async()=>{
    const data=await axios.get(API_CONFIG.API_HOST+CATGORY_API_PATH.LIST_PATH,{
      headers:API_HEADERS
    })
    consoleLog("data",data.data.result)
    setCategoryList(data.data.result)
}
 
const categoryItems =()=>{
  return(
      categoryList.map((item,i)=>{
        return(
          <option value={item.id} key={item.id}>{item.name}</option>
        )
      })
  )
   
}

  return (
    <>
      <ToastContainer />
      <main className="container-fluid dashboard">
        <div className="row m-3">
          <h3>Update Store</h3>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-3">
                <label>Category</label>
                <select
                  className="form-control"
                  name="selectCategory"
                  value={inputValidation.selectCategory}
                  onChange={handleChange}
                >
                  <option>{"--select category--"}</option>
                  {categoryItems()}
                </select>

                {inputValidation.selectCategory === "" && (
                  <span className="text-danger">*Required</span>
                )}
              </div>

              <div className="col-lg-3">
                <label>Store Name</label>
                <input
                  type="text"
                  name="storeName"
                  value={inputValidation.storeName}
                  onChange={handleChange}
                  class="form-control"
                  placeholder="Store Name"
                ></input>

                {inputValidation.storeName === "" && (
                  <span className="text-danger">*Required</span>
                )}
              </div>

              <div className="col-lg-3">
                <label>Contact Name</label>
                <input
                  type="text"
                  name="contactName"
                  value={inputValidation.contactName}
                  onChange={handleChange}
                  class="form-control"
                  placeholder="Contact Name"
                ></input>

                {inputValidation.contactName === "" && (
                  <span className="text-danger">*Required</span>
                )}
              </div>

              <div className="col-lg-3">
                <label>Contact Number</label>
                <input
                  type="number"
                  name="contactNo"
                  value={inputValidation.contactNo}
                  onChange={handleChange}
                  class="form-control"
                  placeholder="Contact  No"
                ></input>

                {inputValidation.contactNo === "" && (
                  <span className="text-danger">*Required</span>
                )}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-3">
              <label style={labelStyle}>Search City/Town</label>{" "}
              
              <AutoComplete
                className="form-control"
                style={{ width: "100%" }}
                name="location"
               value= {inputValidation.location}
               options={{
                      types: ["geocode", "establishment"],
                    }}
                onChange={(e) => handleChange(e)}
                apiKey={"AIzaSyCB14naEHjDiVBFLEqhPBMUA9eqg_4yRdk"}
                onPlaceSelected={(place) => updatePlaceInfo(place)}
                // onChange={(e)=> setInputValidation({...inputValidation,location:e.target.value})}
                placeholder="Enter city name"
              />
              {(areaName==="")&&<span className="text-danger">*Required</span>}
              </div>

              <div className="col-lg-3">
              <label>GST No</label>
                <input
                  type="text"
                  name="gstNo"
                  value={inputValidation.gstNo}
                  onChange={handleChange}
                  class="form-control"
                  placeholder="GST No"
                ></input>

                {/* {inputValidation.gstNo === "" && (
                  <span className="text-danger">*Required</span>
                )} */}
              </div>

              <div className="col-lg-3">
              <label>Fssai No</label>
                <input
                  type="text"
                  name="fssaiNo"
                  value={inputValidation.fssaiNo}
                  onChange={handleChange}
                  class="form-control"
                  placeholder="Fssai No"
                ></input>

                {/* {inputValidation.fssaiNo === "" && (
                  <span className="text-danger">*Required</span>
                )} */}
              </div>

              <div className="col-lg-3">
                <label>Radius</label>
                <input
                  type="number"
                  name="radius"
                  value={inputValidation.radius}
                  onChange={handleChange}
                  class="form-control"
                  placeholder="Radius"
                ></input>

                {inputValidation.radius === "" && (
                  <span className="text-danger">*Required</span>
                )}
                
              </div>
              <div className="col-lg-3 mt-3">
                <label>Delivery Charge</label>
            <input
                  type="number"
                  name="deliveryCharge"
                  value={inputValidation.deliveryCharge}
                  onChange={handleChange}
                  class="form-control"
                  placeholder="Delivery Charge"
                ></input>
                {inputValidation.deliveryCharge === "" && (
                  <span className="text-danger">*Required</span>
                )}
                </div>
                <div className="col-lg-3 mt-3">
                <label>Free min order</label>
                <input
                  type="number"
                  name="minFree"
                  value={inputValidation.minFree}
                  onChange={handleChange}
                  class="form-control"
                  placeholder="free min order"
                ></input>
            </div>
            <div className="col-lg-3 mt-3">
            <label>Delivery Time</label>
                <input
                  type="text"
                  name="deliveryTime"
                  value={inputValidation.deliveryTime}
                  onChange={handleChange}
                  class="form-control"
                  placeholder="Delivery Time"
                ></input>
                {inputValidation.deliveryTime === "" && (
                  <span className="text-danger">*Required</span>
                )}
            </div>
            <div className="col-12 col-md-6 col-lg-3 mt-3">
                <label>Assign To FO</label>
                <select 
               
                onChange={handleChange} 
               
                name="fo" 
                value={inputValidation.fo} 
                className="form-control"
                >
                  <option value="">{"--Assign To Franchise Owner--"}</option>
                  {
                    foList?foList.length>0&&foList.map(item=>{
                      return(
                        <option value={item.id}>{item.name}</option>
                      )
                    }):<>No data found</>
                  }
                </select>
              </div>
            </div>
            
            <div class="mr-auto col-12 text-end mt-3">
              <button
                type="button"
                class="btn btn-danger me-2"
                onClick={() => naviagte(-1)}
              >
                Cancel
              </button>
              <button
                type="button"
                class="mr-auto btn background-color-change me-2"
                onClick={Validation}
              >
                Save
              </button>
              {consoleLog("final input", inputValidation)}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default UpdateStore;
