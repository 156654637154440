import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Deliveryorder from "./delivery-orders";
import { useLocation } from "react-router-dom";
import DeliveryPayments from "./delivery-payments";
import { consoleLog } from "../../../utils/log-utils";

function UniqueDelivery(){
    const location=useLocation();

    const dbId=location.state!==null?location.state.db.id:""
    const event=location.state!==null?location.state.db.event:""
    const data=location.state!==null?location.state.db:""
    consoleLog("data",data)
    return(
        <>
        <main className="container-fluid dashboard">
        <div className="row m-3">
        <div className="col-6">
              <h3 className="dashboard-title">{data.name}</h3>
              <h4 className="dashboard-title">{data.city}</h4>
            </div>
          <div className="col-12 mt-3">
            <Tabs
              defaultActiveKey={event}
              id="uncontrolled-tab-example"
              className=""
            >
              <Tab eventKey="dbOrders" title="Orders">
                <Deliveryorder id={dbId} />
              </Tab>
              <Tab eventKey="dbPayments" title="Settlements">
                <DeliveryPayments id={dbId} />
              </Tab>
              
            </Tabs>
          </div>
          </div>
          </main>
        </>
    )

}
export default UniqueDelivery;