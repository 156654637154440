import React, { useEffect, useState } from "react";

import axios from "axios";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { LOCATION_API_PATH, PAYMENTS_API_PATH, USER_API_PATH } from "../../network/config/apiPaths";
import PaymentsFranchiseListItem from "./payments-fo-list-item";
import { consoleLog } from "../../utils/log-utils";
import Select from "react-select";


function PaymentsFranchiseList() {
  const navigate=useNavigate();
  const dispatch=useDispatch();
  const [apiResult, setApiResult] = useState([]);

  const [searchVal, setSearchVal] = useState("");
 
  const [filterList, setFilterList] = useState([]);

  const [isLoading,setIsLoading] = useState(false)

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedFo, setSelectedFo] = useState(null);
  const [locationList,setLocationList]=useState([])


  const [foList,setFoList]=useState([])

  

  useEffect(() => {
    UserList();
  }, []);
  useEffect(()=>{
   
    fetchLocation();
    
  },[selectedFo])
  useEffect(()=>{
    fetchFoList()
  },[selectedLocation])


  const UserList = async () => {
    setIsLoading(true)
    const data = await axios.get(
      API_CONFIG.API_HOST +"Payments/foPaymentlist",
      {
        headers: API_HEADERS,
      }
    );
    setIsLoading(false)
    consoleLog("fo payments",data)
    if(data.data.result){
    setApiResult(data.data.result);
    setFilterList(data.data.result)
    }
  };
  const fetchLocation = async () => {
    let data = await axios.get(
      API_CONFIG.API_HOST + LOCATION_API_PATH.LOCATION_LIST_PATH,
      {
        headers: API_HEADERS,
      }
    );
    setLocationList(data.data.result);
    consoleLog("setLocationList", data.data.result);
  };
  async function fetchFoList() {
    if (selectedLocation !== null) {
      let apiPath =
        API_CONFIG.API_HOST +
        `user/getNearbyUsers?lat=${selectedLocation.latitude}&lng=${selectedLocation.longitude}&roleId=9`;

      const data = await axios.get(apiPath, {
        headers: API_HEADERS,
      });

      // setIsLoading(false);
      consoleLog("FoResult", data.data.result);

      setFoList(data.data.result);
      // setResultList(data.data.result);
    }
  }

  
  return (
    <>
    
    <main className="container-fluid dashboard">
      <div className="row m-3">
        <div className="tble">
          <div className="col-12">
            <h3 className="dashboard-title">Franchise Owner</h3>
          </div>
          
          
        </div>
      </div>
      <div className="row m-3">
      
      <div className="col-md-2">
            <div className="form-group">
              <label>Location</label>
              <Select
                options={locationList}
                value={selectedLocation !== "" ? selectedLocation : ""}
                getOptionLabel={(option) => `${option.location_name}`}
                getOptionValue={(option) => `${option.location_name}`}
                onChange={(sOption) => {
                  setSelectedLocation(sOption);
                }}
                // isMulti
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label>FO</label>
              <Select
              options={foList}
              value={selectedFo !== "" ? selectedFo : ""}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => `${option.id}`}
              onChange={(sOption) => {
                setSelectedFo(sOption);
              }}
              />
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label>Month/Year</label>
              <input type="date" className="form-control"/>
            </div>
          </div>
        <div className="col-md-12 card-1 h-scroll">
          <table className="table table-hover align-middle">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Month</th>
                <th scope="col">Area</th>
                <th scope="col">Fo Name</th>
                <th scope="col">Fo Amount</th>
                <th scope="col">Pending Amount</th>
                  <th scope="col">Settled Amount</th>
                <th scope="col">Transaction Date</th>

                <th scope="col">Transaction Id</th>

                <th scope="col">Status</th>

                {/* <th scope="col">Contact no</th> */}
                
               
                {/* <th scope="col">%</th> */}
                {/* <th scope="col">Status</th> */}
                
              </tr>
            </thead>

            <tbody>
              {
                isLoading ? (
                  <>Loading</>
                ):
                filterList&&filterList.length>0?
              filterList.map((obj,i) => {
                return <PaymentsFranchiseListItem item={obj} key={obj.id} sno={i+1} />;
              }):(
                <>No data</>
              )
            }
            </tbody>
          </table>
        </div>
      </div>
    </main>
    </>
  );
}

export default PaymentsFranchiseList;