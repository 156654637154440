import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listAction } from "../../network/store/action/ProductResponseAction";
import { listAction as categoryListAction } from "../../network/store/action/CategoryResponseAction";
import { listAction as subCategoryListAction } from "../../network/store/action/SubCategoryResponseAction";

import noImage from "../../assets/no_image.jpg";
import ImageUpload from "../../components/image-upload";
// import { Table } from "react-bootstrap";
import { FaEye, FaTrash, FaEdit, FaPlus } from "react-icons/fa";
import "./products.css";
import { useNavigate } from "react-router-dom";
import ProductListItem from "./product-list-item";
import {
  formUpdateData,
  formUpdateStatus,
  imageUploadStatus,
  largeImageStatus,
} from "../../redux/slice/formUpdateSlice";
import AppPagination from "../../components/pagination";
import {
  isCustomerSupport,
  isEmployee,
  isFranchisLogin,
} from "../../utils/userRoles";
import { consoleLog } from "../../utils/log-utils";
import FullImageView from "../../components/full-image";

function Products() {
  // to get api data

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showRequestForm = useSelector(formUpdateStatus);
  const imageUpload = useSelector(imageUploadStatus);
  const displayLargeImage = useSelector(largeImageStatus);
  const formData = useSelector(formUpdateData);

  // const slanguage = useSelector((state) => {
  //   console.log("State: ", state.rootReducer);
  //   return state.rootReducer.formUpdateReducer.displayLargeImage;
  // });

  const [sectionList, setSectionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  // list of items
  const [resultList, setResultList] = useState([]);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);

  //Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(20);
  const [pageOffset, setPageOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const [productAddForm, setproductAddForm] = useState({
    sectionId: "",
    subCategoryId: "",
    searchProduct: "",
  });
  const [isProductUpdate, setIsProductUpdate] = useState(false);

  useEffect(() => {
    getSections();
    getProducts(
      pageLimit,
      pageOffset,
      productAddForm.sectionId,
      productAddForm.subCategoryId,
      productAddForm.searchProduct
    );
  }, []);

  useEffect(() => {
    getProducts(
      pageLimit,
      pageOffset,
      productAddForm.sectionId,
      productAddForm.subCategoryId,
      productAddForm.searchProduct
    );
  }, [
    pageLimit,
    pageOffset,
    productAddForm,
    isProductUpdate,
    productAddForm.searchProduct,
  ]);

  const getSections = async () => {
    await dispatch(categoryListAction({}, dispatch)).then((response) => {
      consoleLog("sectionList", response.result);
      if (response.result) {
        setSectionList(response.result);
      }
    });
  };

  const getCategories = async (catId) => {
    setCategoryList([]);
    await dispatch(
      subCategoryListAction(
        {
          secId: catId,
        },
        dispatch
      )
    ).then((response) => {
      if (response.result) {
        setCategoryList(response.result);
      }
    });
  };
  const getProducts = async (
    limit,
    offset,
    sectionId,
    subCategoryId,
    keyword
  ) => {
    await dispatch(
      listAction(
        {
          limit: limit,
          offset: offset,
          categoryId: sectionId,
          subCategoryId: subCategoryId,
          keyword: keyword,
        },
        dispatch
      )
    ).then((reponse) => {
      if (reponse.result) {
        consoleLog("response", reponse);
        // if (productAddForm.searchProduct !== "") {
        //   ProductSearch(reponse.result);
        // } else {

        // }
        setResultList(reponse.result);
        setTotalCount(Number(reponse.itemCount.totalFilterCount));
        setPageCount(reponse.itemCount.pageCount);
      } else {
        resetFilter();
      }
    });
  };

  const resetFilter = () => {
    setPageOffset(0);
    setTotalCount(0);
    setResultList([]);
    setPageCount(0);
    setActivePage(1);
  };

  const handlePageChange = (selectedPage) => {
    setActivePage(selectedPage);
    setPageOffset((selectedPage - 1) * pageLimit);
  };

  const handleLimitChange = (e) => {
    setPageLimit(e.target.value);
    setPageOffset(0);
    setActivePage(1);
  };

  const handleChanges = (e) => {
    if (e.target.name === "sectionId") {
      setproductAddForm({
        ...productAddForm,
        subCategoryId: "",
        sectionId: e.target.value,
      });
      getCategories(e.target.value);
    } else {
      setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value });
    }
  };

  const ProductSearch = (response) => {
    //
    // let searchTerm = productAddForm.searchProduct;
    // const res = response.filter((item) => {
    //   if (item.product_name.toLowerCase().includes(searchTerm)) {
    //     return item;
    //   }
    // });
    // const filterBySearch = apiResult.filter((item) => {
    //   if (item.phone.includes(searchTerm)) {
    //     return item;
    //   }
    // });
    //  setResultList(res);
  };

  return (
    <>
      {imageUpload && (
        <ImageUpload
          product={formData}
          onCancelClick={(reloadStatus) => {
            setIsProductUpdate(reloadStatus);
          }}
        />
      )}

      {displayLargeImage && (
        <>
          <FullImageView
            product={formData}
            onCancelClick={(reloadStatus) => {
              setIsProductUpdate(reloadStatus);
            }}
          />
        </>
      )}

      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">Products</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              {!isFranchisLogin() && !isCustomerSupport() && (
                <>
                  <button
                    type="button"
                    className="mr-auto btn add-button-color"
                    onClick={() => navigate("/addProduct")}
                  >
                    + Add Product
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-3">
            <div className="form-group">
              <label>Search Product</label>
              <input
                type="text"
                name="searchProduct"
                className="form-control"
                value={productAddForm.searchProduct}
                onChange={handleChanges}
                placeholder="Enter Product name"
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Section</label>
              <select
                name="sectionId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={productAddForm.sectionId}
              >
                <option value={""}>{"--Select Category--"}</option>
                {sectionList &&
                  sectionList.length > 0 &&
                  sectionList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>Category</label>
              <select
                name="subCategoryId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={productAddForm.subCategoryId}
              >
                <option value={""}>{"--Select Sub Category--"}</option>
                {categoryList &&
                  categoryList.length > 0 &&
                  categoryList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="col-md-12 card-1 h-scroll">
            <table className="table table-hover align-middle" id="table">
              <thead className="thead-dark">
                <tr id="Row1">
                  <th scope="col">Id</th>
                  <th scope="col">Image</th>
                  <th scope="col">NX Image</th>
                  <th scope="col">Name</th>
                  {/* <th scope="col">Summary</th> */}
                  <th colSpan={4}>Nutrition Index</th>
                  {!isFranchisLogin() && !isCustomerSupport() && (
                    <>
                      <th scope="col">Actions</th>
                    </>
                  )}
                </tr>
                <tr id="Row2">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <th>Calories</th>
                  <th>Fat</th>
                  <th>Carb</th>
                  <th>Protein</th>
                  <td></td>
                </tr>
              </thead>
              {resultList && resultList.length > 0 ? (
                <tbody>
                  {resultList.map((item, i) => {
                    return (
                      <ProductListItem
                        product={item}
                        key={item.id}
                        // storeId={productAddForm.storeId}
                      />
                    );
                  })}
                </tbody>
              ) : (
                <p>No Data found</p>
              )}
            </table>
            <AppPagination
              pageCount={pageCount}
              activePage={activePage}
              totalCount={totalCount}
              onPageChange={(page) => handlePageChange(page)}
              onLimitChange={(e) => handleLimitChange(e)}
            />
          </div>
        </div>
      </main>
    </>
  );
}
export default Products;
