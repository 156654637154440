import { FaEdit, FaTrash } from "react-icons/fa";
import { displayRemoveAlert } from "../../../redux/slice/formUpdateSlice";
import { useDispatch } from "react-redux";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { BANNER_API_PATH } from "../../../network/config/apiPaths";
import { axiosApi } from "../../../network/service/config/AaxiosUtil";
import { updateMessage } from "../../../redux/slice/toastSlice";
import { useState } from "react";
import { getImagePath } from "../../../utils/util";

function BannerItem(props) {
  const dispatch = useDispatch();
  // const [product, setItem] = useState(props.result);
  // const [itemStatus, setitemStatus] = useState(product.status);

  const deleteItem = () => {
   
    dispatch(
      displayRemoveAlert({
        displayRemove: true,
        formData: { item: props.result },
        type: "banner",
      })
    );
  };
  // const onStatusChange = async () => {
  //   let status = "ACTIVE";
  //   if (product.status == "NO") {
  //     status = "ACTIVE";
  //   } else {
  //     status = "NO";
  //   }

  //   const requestBody = {
  //     status: status,
  //     id: product.id,
  //   };
  //   console.log(requestBody)
  //   await axiosApi
  //     .post(API_CONFIG.API_HOST + BANNER_API_PATH.UPDATE_BANNER, requestBody, {
  //       API_HEADERS,
  //     })
  //     .then((response) => {
        
  //       console.log("STORE UPODATE", response);
  //       if (response.status === 200 && response.data.status === true) {
  //         setItem(product, (product.status = status));
  //         setitemStatus(status);
  //       } else {
  //         dispatch(
  //           updateMessage({
  //             display: true,
  //             message: "Error While updating info",
  //           })
  //         );
  //       }
  //     });

  //   // await dispatch(createUpdateAction(requestBody)).then((response) => {
  //   //   if (response.status == true) {
  //   //     setItem(product, (product.status = status));
  //   //     setitemStatus(status);
  //   //   } else {
  //   //     dispatch(
  //   //       updateMessage({
  //   //         display: true,
  //   //         message: "Error While updating info",
  //   //       })
  //   //     );
  //   //   }
  //   // });
  // };

  return (
    <>
      <tr>
        <td>{props.id}</td>
        <td>{props.result.type}</td>
        {/* <td>{props.result.storeName}</td> */}
        <td>
          {props.result.option_key} -{" "}
          {props.result.option_key === "Tags"
            ? props.result.tagName
            : props.result.option_value}
        </td>
        <td>{props.result.name}</td>
        <td>
          <img
            src={getImagePath(props.result.image_path)}
            className="img-thumbnail"
            style={{ width: 75 }}
          />
        </td>

        {/* <td>
        <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              onChange={onStatusChange}
              checked={itemStatus === "ACTIVE" ? true : false}
            />
          </div>
        </td> */}
        <td className="align-middle">
          <div className="d-flex justify-content-center">
            <span className="delete-icon-btn" onClick={deleteItem}>
              <FaTrash />
            </span>
          </div>
        </td>
      </tr>
    </>
  );
}

export default BannerItem;
