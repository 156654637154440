import axios from "axios"
import { useEffect, useState } from "react"
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig"
import { APP_VERSION_API_PATH } from "../../network/config/apiPaths"
import TableItem from "./table-item"
import { consoleLog } from "../../utils/log-utils"

function AppVersionTable() {

    const [appList, setAppList] = useState({})
    useEffect(() => {
        appVersion();
       
        
    }, [])

    const appVersion = async () => {
        let data = await axios.get(API_CONFIG.API_HOST + APP_VERSION_API_PATH.APP_LIST_PATH, {
            headers: API_HEADERS
        })
       
        consoleLog("appversion",data.data.result)
        setAppList(data.data.result)
    }
    return (
        <>
            <main className="container-fluid dashboard">
                <div className="row m-3">
                    <h3>App Version</h3>
                    <div className="col-md-12 card-1 h-scroll mt-4">
                        <table className="table table-hover align-middle">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">ID</th>

                                    <th scope="col">User Android</th>
                                    <th scope="col">User Ios</th>
                                    <th scope="col">Employee Android</th>
                                    <th scope="col">Employee Ios</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    
                                    appList && 
                                    <>
                                    <TableItem app={appList} key={appList.id} />
                                        
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
        </>
    )
}
export default AppVersionTable;