import { useState, memo, useRef } from "react";
import {
  FaEdit,
  FaImage,
  FaPlus,
  FaRegEdit,
  FaTag,
  FaTrash,
  FaEye,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import {
  displayLargeImage,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import { createUpdateAction } from "../../network/store/action/ProductResponseAction";
import { updateMessage } from "../../redux/slice/toastSlice";
import { useNavigate } from "react-router-dom";
import noImage from "../../assets/no_image.jpg";
import { productNxImageUplaodRequest } from "../../network/service/ImageUploadService";
import {
  isCustomerSupport,
  isEmployee,
  isFranchisLogin,
} from "../../utils/userRoles";
import { API_CONFIG } from "../../network/config/ApiConfig";
import { getImagePath } from "../../utils/util";
import { consoleLog } from "../../utils/log-utils";

const ProductListItem = (props) => {
  const [product] = useState(props.product);
  const [itemStatus, setitemStatus] = useState(product.status);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState();

  
  const editItem = () => {
    dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/udprdct");
  };
  const showLargeImage = () => {
    dispatch(
      displayLargeImage({ displayLargeImage: true, formData: { product } })
    );
  };

  const editTags = () => {
    dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/ptags");
  };

  const onImageClick = () => {
    dispatch(updateFormInfo({ imageUpload: true, formData: { product } }));
  };

  const onNgImageClick = () => {
    hiddenFileInput.current.click();
  };

  const handleImageInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded != null) {
      setFile(URL.createObjectURL(fileUploaded));
      console.log("file upload", fileUploaded);
      uploadImage(fileUploaded);
    }
  };

  const uploadImage = async (file) => {
    const form_data = new FormData();
    form_data.append("file", file);
    let requestBody = {
      id: product.id,
      fileData: form_data,
    };
    console.log("requestbody", requestBody);
    await dispatch(productNxImageUplaodRequest(requestBody)).then(
      (response) => {
        consoleLog(response);
      }
    );
  };

  return (
    <tr>
      <td className="align-middle">
        <p>{product.id}</p>
      </td>

      <td className="align-middle">
        <div className="cat_mainwrap" onClick={() => onImageClick()}>
          {/* {product.productImage === null ? null : (
            <div className="cat_btnwrap">
              <a href="">
                <FaRegEdit />
              </a>
              <a href="">
                <FaEye />
              </a>
            </div>
          )} */}

          {product.productImage !== "" ? (
            <img
              src={getImagePath(product.productImage)}
              alt={product.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          ) : (
            <img
              src={noImage}
              alt={product.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          )}
        </div>
      </td>
      <td className="align-middle">
        <div className="cat_mainwrap">
          {product.image_path === "" ? null : (
            <div className="cat_btnwrap">
              <FaRegEdit className="faWhite" onClick={() => onNgImageClick()} />

              <FaEye className="faWhite" onClick={() => showLargeImage()} />
            </div>
          )}
          <input
            type="file"
            onChange={handleImageInputChange}
            ref={hiddenFileInput}
            style={{ display: "none" }} // Make the file input element invisible
          />
          {file ? (
            <>
              <img
                src={file}
                alt={product.name}
                className="img-thumbnail"
                width="50px"
                height="50px"
              />
            </>
          ) : product.image_path !== "" ? (
            <img
              src={getImagePath(product.image_path)}
              alt={product.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          ) : (
            <img
              onClick={() => onNgImageClick()}
              src={noImage}
              alt={product.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          )}
        </div>
      </td>
      <td className="align-middle">
        <p>{product.product_name}</p>
      </td>

      <td className="align-middle">{product.cal_grm}g</td>
      <td className="align-middle">{product.fat_grm}g</td>
      <td className="align-middle">{product.carbs_grm}g</td>
      <td className="align-middle">{product.protein_grm}g</td>
      {!isFranchisLogin() && !isCustomerSupport() && (
        <>
          <td className="align-middle">
            <p className="col-lg-12 d-flex justify-content-center">
              <span
                className="edit-icon-btn"
                onClick={() => {
                  editItem();
                }}
              >
                <FaRegEdit />
              </span>
              <span
                className="delete-icon-btn tag-icon-btn"
                onClick={() => {
                  editTags();
                }}
              >
                <FaTag />
              </span>
            </p>
          </td>
        </>
      )}
    </tr>
  );
};

export default memo(ProductListItem);
