import { useState } from "react";


function DbPaymentItem(props){
    const [data]=useState(props.item)
    
    return(
        <>
        <tr>
        <td> {data.duration}</td>
            {/* <td>{data.foName}</td> */}
        
            <td>{data.order_id}</td>
            
            {/* <td>{data.orders_count}</td> */}
            
            <td>₹{data.orderPayment}</td>
            <td>₹{data.storePayment}</td>
            <td>₹{data.appPayment }</td>
            <td>₹{data.deliveryCharges}</td>
            <td>₹{data.packageCharge}</td>

            <td>₹{data.platformFee}</td>
            <td>₹{data.couponCost}</td>
            
            <td>₹{data.foPayment}</td>
            <td>₹{data.finalPayShare}</td>
        </tr>
        </>
    )
}
export default DbPaymentItem;