import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isFranchisLogin } from "../../utils/userRoles";

function PaymentOverviewItem(props){
    const[data]=useState(props.item)
    const navigate=useNavigate();
    const  navigatePaymentsDetailPage=()=>{
        navigate("/franchisePayments",{state:{storeId:data.storeId}})
    }
    const  navigateStorePayments=()=>{
        navigate("/adminfopayments",{state:{storeId:data.storeId,storeName:data.storeName,foId:props.foId,foName:props.foName}})
    }
    return(
        <>
        {
            isFranchisLogin()?<>
<tr>
            <td>{props.sno}</td>
            <td onClick={navigatePaymentsDetailPage}>
                <Link>{data.storeName}</Link>
                </td>
            {/* <td> {data.dated}</td> */}
            <td>{data.ordersCount}</td>
            <td>₹{data.totalAmount}</td>
            <td>₹{data.foPayment}</td>
            
        </tr>
            </>:<><tr>
            <td>{props.sno}</td>
            <td onClick={navigateStorePayments}>
                <Link>{data.storeName}</Link>
                </td>
                <td>{data.ordersCount}</td>
            <td>₹{data.totalAmount}</td>
            
            
            
        </tr>
        </>
        }
        
        </>
    )
}
export default PaymentOverviewItem;