import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { consoleLog } from "../../../utils/log-utils";
import { formatedDate } from "../../../utils/date-utils";


function FranchisePaymentItem(props) {
  const [data] = useState(props.item);
  const navigate=useNavigate();
  consoleLog("data..", data);
  const storeInfoPage = () => {
    // dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/uniqueStore", {
      state: {
        storeId: data.store_id,
        storeName: data.storeName,
        storeAddress: data.deliveryCity,
        event:"payments"
      },
    });
  };
  const deliveryPage = () => {
    // dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/uniqueDelivery", {
      state: {
        deliveryId: data.storeId,
        dName: data.storeName,
        
        event:"payments"
      },
    });
  };
  return (
    <>
    
      
      <tr>
       
        <td>
          {formatedDate(data.dated)}
          </td>
        
        <td>₹{data.orderPayment}</td>
        
        
        <td>₹{data.storePayment}</td>
        <td>₹{data.appPayment }</td>
        <td>₹{data.deliveryCharges}</td>
        <td>₹{data.packageCharge}</td>

<td>₹{data.platformFee}</td>
<td>₹{data.couponCost}</td>
<td>₹{data.finalPayShare}</td>
<td>₹{data.foPayment}</td>

        
        
       
      </tr>
    </>
  );
}

export default FranchisePaymentItem;
