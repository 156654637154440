import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { USER_API_PATH } from "../../network/config/apiPaths";
import { axiosApi } from "../../network/service/config/AaxiosUtil";
import { consoleLog } from "../../utils/log-utils";
import { useToastMsg } from "../hooks/useToastMsg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function Profile() {
    const [details,setDetails]=useState({
        name:localStorage.getItem("userName"),
        phone:localStorage.getItem("userPhone"),
        email:localStorage.getItem("userEmail"),
        password:true,
        old:"",
        new:"",
        confirm:"",
    })
    const navigate=useNavigate()
    const reset=()=>{

    }
    const [success, warning] = useToastMsg(
        "Successfully Updated",
        "Password Not Matching",
        reset
      );
    
    const handleChanges = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value });
      };
      const updateProfile=async()=>{
        let requestDetails={
           oldPassword:details.old,
           password:details.new,
           id:localStorage.getItem("userId")
        }
        const data=await axiosApi.post(API_CONFIG.API_HOST+USER_API_PATH.UPDATE_USER_PWD,requestDetails,{
               headers:API_HEADERS
        })
        consoleLog("user profile update",data)
        if(data.data.status===true){
            
            success()
        }
        else{
            warning()
        }
      }

      const Validation=()=>{
        details.old===""|| details.new===""||details.confirm===""?warning():details.new !==details.confirm?warning():updateProfile()
      }
    return (
        <>
            <main className="container-fluid dashboard">
                <div className="d-flex flex-row justify-content-center">
                    <div className="row m-3 shadow-lg profile-width h-25">
                        <div className="col-11 m-4">
                            <label>Name</label>
                            <input  value={details.name} className="form-control" />
                            <label>phone Number</label>
                            <input  value={details.phone} className="form-control"/>
                            <label>Email</label>
                            <input value={details.email} className="form-control"/>
                            {/* <div className="d-flex flex-row justify-content-end">
                                <Link onClick={()=>setDetails({...details,password:true})}>Change Password</Link>
                            </div> */}
                            {
                                details.password&&<>
                                <label>Old Password</label>
                                <input name="old" value={details.old} className="form-control" onChange={(e)=>handleChanges(e)} />
                                <label>New Password</label>
                               <input name="new" value={details.new} className="form-control" onChange={(e)=>handleChanges(e)} />
                               <label>Confirm Password</label>
                               <input name="confirm" value={details.confirm} className="form-control" onChange={(e)=>handleChanges(e)} />
                               {/* <div className="d-flex flex-row justify-content-end">
                               <Link onClick={()=>setDetails({...details,password:false})}>Back</Link>
                               </div> */}
                               {
                                details.new!==details.confirm&&<span className="text-danger">Enter correct password</span>
                               }
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="row d-flex flex-row justify-content-center">
                    <div className="col-3 d-flex flex-row justify-content-center">
                        <button className="btn btn-danger me-2" onClick={()=>navigate("/dashboard")}>Close</button>
                         <button className="btn btn-purple-bg" onClick={Validation}>Update</button>
                </div>
                <ToastContainer />
                </div>
            </main>
        </>
    )
}
export default Profile