import { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

function PaymentDbListItem(props) {
  const dispatch=useDispatch()
  const navigate=useNavigate();
  const[product] = useState(props.item)
//   const deleteItem = () =>{
//     dispatch(displayRemoveAlert({ displayRemove: true, formData: { item:product },type:"users" }));
//   }
  const deliveryPayments=()=>{
     navigate("/dbPayments",{state:{db:product}})
  }
//   const editItem=()=>{
//     navigate("/userEdit",{state:{item:product}})
//   }
  return (
    <>
      <tr>
        <td>{props.sno}</td>
      <td>{product.city}</td>
      <td> {product.franchiseOwner} </td>
        <td onClick={deliveryPayments}>
          <Link className="anchor-color-change">{product.name}</Link>
        </td>
        {/* <td> {product.phone} </td> */}
       
        
        {/* <td className="align-middle">
            <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
                <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    checked={product.status === "ACTIVE" ? true : false}

                />
            </div>
        </td> */}
        
        {/* <td className="align-middle">
                    <div className="d-flex justify-content-center">
                    <span className="edit-icon-btn" onClick={editItem}>
                      <FaEdit/>
                    </span>
                    
                    </div>
                </td> */}
      </tr>
    </>
  );
}

export default PaymentDbListItem;
