import { useState } from "react"
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { consoleLog } from "../../utils/log-utils";

function TableItem(props){
    const item=useState(props.app);
    
    consoleLog("item",item)
    const navigate=useNavigate();
    const editItem = () => {
        
        navigate("/app version",{state:{app:item[0]}});
      };
   return(
    <>
    <td>{item[0].id}</td>
    <td>{item[0].android_version}</td>
    <td>{item[0].ios_version}</td>
    <td>{item[0].android_employee_version}</td>
    <td>{item[0].ios_employee_version}</td>
    <td className="align-middle">
        <p className="col-lg-12 d-flex justify-content-center">
          <span
            className="edit-icon-btn"
            onClick={() => {
              editItem();
            }}
          >
            <FaRegEdit />
          </span>
          </p>
          </td>
    </>
   )
}
export default TableItem