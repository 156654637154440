import { consoleLog } from "../../utils/log-utils";
import { CATGORY_API_PATH, PRODUCT_API_PATH } from "../config/apiPaths";
import { getRequest } from "./config/GetRequest";
import { postRequest } from "./config/PostRequest";

export const listRequest = (data) => async () => {
  consoleLog("listrRequest",data.keyword)
  if (data.keyword) {
    try {
      let result = await getRequest(
        `${PRODUCT_API_PATH.SEARCH_LIST_PATH}?limit=${data.limit}&offset=${data.offset}&categoryId=${data.categoryId}&keyword=${data.keyword}`,
        null,
        false
      );
      return result;
    } catch (error) {
      consoleLog("",error);
      // TODO Handle Error
    }
  } else {
    try {
      let result = await getRequest(
        `${PRODUCT_API_PATH.LIST_PATH}?limit=${data.limit}&offset=${data.offset}&categoryId=${data.categoryId}&subCategoryId=${data.subCategoryId}`,
        null,
        false
      );
      return result;
    } catch (error) {
      consoleLog("",error);
      // TODO Handle Error
    }
  }
};

export const tagListRequest = (data) => async () => {
  try {
    let result = await getRequest(
      `${PRODUCT_API_PATH.TAG_LIST_PATH}?productId=${data.productId}`,
      null,
      false
    );
    return result;
  } catch (error) {
    consoleLog("",error);
    // TODO Handle Error
  }
};

export const imageListRequest = (data) => async () => {
  try {
    let result = await getRequest(
      `${PRODUCT_API_PATH.PRODUCT_IMAGE_LIST}?id=${data.id}`,
      null,
      false
    );
    return result;
  } catch (error) {
    consoleLog("",error);
    // TODO Handle Error
  }
};

export const createUpdateRequest = (data) => async () => {
  try {
    let result = await postRequest(
      PRODUCT_API_PATH.PRODUCT_CREATE,
      data,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const updateTags = (data) => async () => {
  try {
    let result = await postRequest(
      PRODUCT_API_PATH.PRODUCT_TAGS_UPDATE,
      data,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};
