import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function CustomerAddressItem(props){
    const[data]=useState(props.item)
    
    return(
        <>
        <tr>
            <td>{props.sno}</td>
            <td>
                {data.area}
                </td>
            {/* <td> {data.dated}</td> */}
            <td>{data.location}</td>
            <td>{data.type}</td>
            <td>{data.pincode}</td>
            
        </tr>
        </>
    )
}
export default CustomerAddressItem;