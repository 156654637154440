import { useEffect, useState } from "react";

import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { LOCATION_API_PATH } from "../../network/config/apiPaths";
import { consoleLog } from "../../utils/log-utils";
import { isFranchisLogin } from "../../utils/userRoles";
import LocationItem from "./location-item";




function Location() {

    const [locationList,setLocationList]=useState([]);

    useEffect(()=>{
        fetchLocations()
    },[])

    const fetchLocations=async()=>{
        let path= isFranchisLogin()?LOCATION_API_PATH.LOCATION_LIST_PATH+`foId=${localStorage.getItem("userId")}`:LOCATION_API_PATH.LOCATION_LIST_PATH
        consoleLog(path)
        let data=await axios.get(API_CONFIG.API_HOST+path,{
            headers:API_HEADERS
        })
        consoleLog(data.data.result)
        setLocationList(data.data.result)
    }


  
  return (
    <>
      <main className="container-fluid dashboard">
        
       
       
          
          
        <div className="row m-3">
        <div className="col-6 mb-3">
              <h3 className="dashboard-title">Locations</h3>
            </div>
          <div className="col-md-12 card-1 h-scroll">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Sno</th>

                  <th scope="col">City</th>
                  
                  <th scope="col">Area name</th>
                 
                </tr>
              </thead>

              <tbody>
                {locationList !== null?
                  locationList.length > 0 &&
                  locationList.map((item, i) => {
                    return (
                      <>
                        <LocationItem sno={i+1} list={item} />
                      </>
                    );
                  }):<>No data found</>
                }
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </>
  );
}

export default Location;
