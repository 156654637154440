import { Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import CustomerOrders from "./customer-orders";
import { isFranchisLogin } from "../../../utils/userRoles";

import { useEffect, useState } from "react";
import CustomerAddress from "./customer-address";

function CustomerInfo() {
    const navigate = useNavigate();
    const location = useLocation();
    const id = location.state !== null ? location.state.id : "";
    const name = location.state !== null ? location.state.name : "";
    const phone = location.state !== null ? location.state.phone : "";
    


    return (
        <>
            <main className="container-fluid dashboard">
                <div className="row m-3 mt-0 d-flex flex-row">
                    <div className="col-12 ">

                        <h3 className="dashboard-title">{name}</h3>
                        {isFranchisLogin() ? "" : <h6 className="dashboard-title">Phone Number: {phone}</h6>}
                        {/* <h6 className="dashboard-title">City: {city === "Not Updated" ? "-----" : city}</h6> */}

                    </div>






                </div>
                <div className="row m-3">
                    <div className="col-12 mt-4">
                        <Tabs
                            defaultActiveKey={"orders"}
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="orders" title="Orders">
                                <CustomerOrders id={id} />
                            </Tab>
                            <Tab eventKey="address" title="Address">
                                <CustomerAddress id={id} />
                            </Tab>

                        </Tabs>
                    </div>
                </div>



            </main>
        </>
    )
}
export default CustomerInfo