import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { consoleLog } from "../../../utils/log-utils";



function FranchiseSettelementItem(props) {
  const [data] = useState(props.item);
  const navigate=useNavigate();
  consoleLog("data..", data);
  const storeInfoPage = () => {
    // dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/uniqueStore", {
      state: {
        storeId: data.store_id,
        storeName: data.storeName,
        storeAddress: data.deliveryCity,
        event:"payments"
      },
    });
  };
  const deliveryPage = () => {
    // dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/uniqueDelivery", {
      state: {
        deliveryId: data.storeId,
        dName: data.storeName,
        
        event:"payments"
      },
    });
  };
  return (
    <>
      <tr>
        <td>{props.sno}</td>
       
        {/* <td>#Svr210524</td> */}
        <td>{data.dated}</td>
        
        <td>{data.orders_amount}</td>
        
        
        <td>{data.transaction_id}</td>
        {/* <td>{data.transaction_date}</td>
        <td>{data.transaction_id}</td> */}
      </tr>
    </>
  );
}

export default FranchiseSettelementItem;
