import React, { useEffect, useState } from "react";

import axios from "axios";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { USER_API_PATH } from "../../network/config/apiPaths";
import PaymentDbListItem from "./payment-db-list-item";



function PaymentDbList() {
  const navigate=useNavigate();
  const dispatch=useDispatch()
  const [apiResult, setApiResult] = useState([]);

  const [searchVal, setSearchVal] = useState("");
 
  const [filterList, setFilterList] = useState([]);

  const[isLoading,setIsLoading]=useState(false)
  
  const [searchLocation,setSearchLocation]=useState("");
  

  useEffect(() => {
    UserList();
  }, []);

  const UserList = async () => {
    setIsLoading(true)
    const data = await axios.get(
      API_CONFIG.API_HOST + USER_API_PATH.LIST_PATH+"7",
      {
        headers: API_HEADERS,
      }
    );
    setIsLoading(false)
    if(data.data.result !== null){
    setApiResult(data.data.result);
    setFilterList(data.data.result)
    }
  };

  

  
  



  
  return (
    <>
    
   
    <main className="container-fluid dashboard">
      <div className="row m-3">
        <div className="tble">
          <div className="col-6">
            <h3 className="dashboard-title">Delivery Boy</h3>
          </div>
         
        </div>
      </div>
      <div className="row m-3">

      <div className="col-md-2">
            <div className="form-group">
              <label>Date/Year</label>
              <input type="date" className="form-control"/>
            </div>
          </div>
        
      <div className="col-md-2">
            <div className="form-group">
              <label>Location</label>
              <input type="text" className="form-control"/>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label>Fo</label>
              <input type="text" className="form-control"/>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label>DB</label>
              <input type="text" className="form-control"/>
            </div>
          </div>
        
        
        <div className="col-12 card-1 h-scroll">
          <table className="table table-hover align-middle">
            <thead className="thead-dark">
              <tr>
              <th scope="col">sno</th>
              <th scope="col">Location</th>
              <th scope="col">Franchise Owner</th>
                
                <th scope="col">Name</th>
                
               
                
                {/* <th scope="col">Status</th> */}
                
              </tr>
            </thead>

            <tbody>
              {
                isLoading ? (
                  <>Loading</>
                ):
                filterList && filterList.length>0 ?
              filterList.map((obj,i) => {
                return <PaymentDbListItem item={obj} key={obj.id} sno={i+1} />;
              }) :(
                <>No data</>
              )
            }
            </tbody>
          </table>
        </div>
      </div>
    </main>
    </>
  );
}

export default PaymentDbList;