import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDt5C9DaJDrKpCcB3jJlFsxWyPCHxCzV7Q",
  authDomain: "eatprotein-1d655.firebaseapp.com",
  projectId: "eatprotein-1d655",
  storageBucket: "eatprotein-1d655.appspot.com",
  messagingSenderId: "904002596950",
  appId: "1:904002596950:web:705b26dca64050a72ccbf7",
  measurementId: "G-5QQJR074TQ"
};

// console.log("*** Firebase Config ***", firebaseConfig);

const firebaseApp = initializeApp(firebaseConfig);
export const  messaging = getMessaging(firebaseApp);

export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          }
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, {
      vapidKey:
      "BKitWP_RjM6d3ug-hp1kRgYz8v-IjDq01GjpJo9T0bcCUFpJvF1BneeiYFG4LJY4-Dgs29pSl_odPhudO9SQhU0",
      serviceWorkerRegistration,
    })
  );

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));
