import { FaStrikethrough } from "react-icons/fa";
import { useSelector } from "react-redux";
import { formUpdateData } from "../../redux/slice/formUpdateSlice";
import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { ORDER_API_PATH } from "../../network/config/apiPaths";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosApi } from "../../network/service/config/AaxiosUtil";
import { isFranchisLogin } from "../../utils/userRoles";
import { consoleLog } from "../../utils/log-utils";

function OrderDetails() {
  //const formData = useSelector(formUpdateData);
  const navigate = useNavigate();
  const location = useLocation();
  const order = location.state!==null?location.state.order:"";
  const from = location.state!==null?location.state.from:"";
  const [response, setResponse] = useState({});

  useEffect(() => {
    orderDetailsApi(from === "store" ? order.order_id : order.id);
  }, []);

  const orderDetailsApi = async (orderId) => {
    const data = await axios.get(
      API_CONFIG.API_HOST + ORDER_API_PATH.ORDER_DETAILS + orderId,
      {
        headers: API_HEADERS,
      }
    );
    consoleLog("order detail", data.data.result);
    setResponse(data.data.result);
  };

  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0;
  };
  function Status(status) {
    switch (status) {
      case "0":
        return <span style={{ color: "black" }}>Pending</span>;

      case "1":
        return <span style={{ color: "pinkAccent" }}>Accepted</span>;

      case "2":
        return <span style={{ color: "blueAccent" }}>Assigned</span>;

      case "3":
        return <span style={{ color: "yellow" }}>Picked Up</span>;
      case "4":
        return <span style={{ color: "green" }}>Delivered</span>;
      case "5":
        return <span style={{ color: "green" }}>Delivered</span>;
      case "6":
        return <span style={{ color: "red" }}>Cancelled</span>;
      case "7":
        return <span style={{ color: "black" }}>Rejected</span>;
      case "8":
        return (
          <span style={{ color: "0xffcc0d0d" }}>Order Cancel Request</span>
        );
    }
  }

  const acceptApiCall = async () => {
    let requestBody = JSON.stringify({
      orderId: response.info.id,
      dbId: "",
      updatedBy: localStorage.getItem,
      userId: response.info.user_id,
      status: "1",
      statusText: "Order Accepted",
      notes: "",
    });

    let data = await axiosApi.post(
      API_CONFIG.API_HOST + ORDER_API_PATH.ORDER_UPDATE,
      requestBody,
      {
        API_HEADERS,
      }
    );
    consoleLog("data", data);
    data.status === 200 && navigate(0);
  };
  
  const pickupApiCall = async () => {
    let requestBody = JSON.stringify({
      orderId: response.info.id,
      dbId: "",
      updatedBy: localStorage.getItem,
      userId: response.info.user_id,
      status: "3",
      statusText: "Order picked",
      notes: "",
    });

    let data = await axiosApi.post(
      API_CONFIG.API_HOST + ORDER_API_PATH.ORDER_UPDATE,
      requestBody,
      {
        API_HEADERS,
      }
    );
    consoleLog("data", data);
    data.status === 200 && navigate(0);
  };
  const deliveredApiCall = async () => {
    let requestBody = JSON.stringify({
      orderId: response.info.id,
      dbId: "",
      updatedBy: localStorage.getItem,
      userId: response.info.user_id,
      status: "4",
      statusText: "Order Delivered",
      notes: "",
    });

    let data = await axiosApi.post(
      API_CONFIG.API_HOST + ORDER_API_PATH.ORDER_UPDATE,
      requestBody,
      {
        API_HEADERS,
      }
    );
    consoleLog("data", data);
    data.status === 200 && navigate(0);
  };

  const assignApiCall = async () => {
    navigate("/orderAssign", { state: { status: response } });
  };

  const cancleApiCall = async () => {
    let requestBody = JSON.stringify({
      orderId: response.info.id,
      dbId: "",
      updatedBy: localStorage.getItem,
      userId: response.info.user_id,
      status: "6",
      statusText: "Order Cancled",
      notes: "",
    });

    let data = await axiosApi.post(
      API_CONFIG.API_HOST + ORDER_API_PATH.ORDER_UPDATE,
      requestBody,
      {
        API_HEADERS,
      }
    );
    consoleLog("data", data);
    data.status === 200 && navigate(0);
  };

  function ButtonsDisplay(status) {
    switch (status) {
      case "0":
        return (
          <>
            <div className="mr-auto col-12 text-end">
              <button
                type="button"
                className="btn btn-purple-bg mr-2"
                style={{ marginRight: 10 }}
                onClick={acceptApiCall}
              >
                Accept
              </button>
              <button
                type="button"
                className="mr-auto btn btn-danger  mr-2"
                onClick={() => cancleApiCall()}
                style={{ marginRight: 10 }}
              >
                Order Cancel
              </button>
              <button type="button" className="btn btn-warning">
                Print
              </button>
            </div>
          </>
        );

      case "1":
        return (
          <>
            <div className="mr-auto col-12 text-end">
              <button
                type="button"
                className="btn btn-purple-bg mr-2"
                style={{ marginRight: 10 }}
                onClick={assignApiCall}
              >
                Assign Order
              </button>
              <button
                type="button"
                className="btn btn-warning"
                style={{ marginRight: 10 }}
              >
                Print
              </button>
              <button
                type="button"
                className="mr-auto btn btn-danger  mr-2"
                onClick={() => cancleApiCall()}
              >
               Order Cancel
              </button>
            </div>
          </>
        );

      case "2":
        return (
          <>
            <div className="mr-auto col-12 text-end">
              <button
              type="button"
              className="mr-auto btn btn-warning  mr-2"
              onClick={() => pickupApiCall()}
              style={{ marginRight: 10 }}>Pick Up

              </button>
              <button
                type="button"
                className="mr-auto btn btn-danger  mr-2"
                onClick={() => cancleApiCall()}
                style={{ marginRight: 10 }}
              >
                Order Cancel
              </button>
              <button type="button" className="btn btn-warning">
                Print
              </button>
            </div>
          </>
        );
        case "3":
          return (
            <>
              <div className="mr-auto col-12 text-end">
                <button
                type="button"
                className="mr-auto btn btn-purple-bg  mr-2"
                onClick={() => deliveredApiCall()}
                style={{ marginRight: 10 }}>Delivered
  
                </button>
                <button
                  type="button"
                  className="mr-auto btn btn-danger  mr-2"
                  onClick={() => cancleApiCall()}
                  style={{ marginRight: 10 }}
                >
                  Order Cancel
                </button>
                <button type="button" className="btn btn-warning">
                  Print
                </button>
              </div>
            </>
          );

      // case "3":
      //   return <span style={{ color: "yellow" }}>Picked Up</span>;
      case "4":
        return (
          <>
            {/* <div className="mr-auto col-12 text-end">
              <button
                type="button"
                className="mr-auto btn btn-danger  mr-2"
                onClick={() => cancleApiCall()}
                style={{ marginRight: 10 }}
              >
                Order Cancel
              </button>

              <button type="button" className="btn btn-warning">
                Print
              </button>
            </div> */}
          </>
        );
      // case "5":
      //   return <span style={{ color: "green" }}>Delivered</span>;
      // case "6":
      //   return <span style={{ color: "red" }}>Cancelled</span>;
      // case "7":
      //   return <span style={{ color: "black" }}>Rejected</span>;
      // case "8":
      //   return (
      //     <span style={{ color: "0xffcc0d0d" }}>Order Cancel Request</span>
      //   );
    }
  }

  return (
    <>
      {isObjectEmpty(response) ? (
        <></>
      ) : (
        <main className="container-fluid dashboard">
          <div className="row m-3">
            <div className="col-lg-12">
              <div className="col-lg-3">
                <h4>{response.storeItems[0].store.name}</h4>
                <h5>{response.storeItems[0].store.city}</h5>
                {/* {}
                <div></div> */}
                {/* <div>{response.address.address}</div> */}
              </div>
            </div>
            <div className="row mt-3 d-flex">
              <div className="col-lg-2">
                <h5>Order Details</h5>
                <div>
                  <b>Order No:</b> #EP0{response.info.id}
                </div>
                <div>
                  <b>Date:</b> {response.info.created_at.substring(0, 10)}
                </div>
                <div>
                  <b>Time:</b> {response.info.created_at.substring(11)}
                </div>
              </div>
              <div className="col-lg-3">
                <h5>Order Status: {Status(response.info.order_status)}</h5>
                <div>
                  <b>Mode of payment:</b> {response.payment[0].pay_mode}
                </div>
              </div>
              <div className="col-lg-3">
                <h5>Customer Info</h5>
                <div>
                  <b>
                    {response.info.customerName} / {response.info.phone}
                  </b>
                </div>
                <div>{response.address.landmark} </div>
              </div>
              {response.storeItems[0].store.dbName && (
                <div className="col-lg-2">
                  <h5>Delivery Excutive</h5>
                  <div>
                    <b>
                      {response.storeItems[0].store.dbName} /{" "}
                      {response.storeItems[0].store.dbPhone}
                    </b>
                  </div>
                </div>
              )}
              <div className="col-lg-2">
                <h5>Comments</h5>
                <div>
                  <b>
                    {response.info.customerName} / {response.info.phone}
                  </b>
                </div>
                <div>{response.address.landmark} </div>
              </div>
            </div>
            <div className="row mt-3 d-flex">
              <div className="col-md-6 col-lg-6 bottom-margin">
                <div className="me-4">
                  <div class="accordion" id="Order_Items">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Order Items
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse show"
                        data-bs-parent="#Order_Items"
                      >
                        <div class="accordion-body">
                          <ol>
                            {response.storeItems[0].items.map((item, i) => {
                              return (
                                <li>
                                  <div class="form-check">
                                    <label
                                      class="form-check-label"
                                      for="flexCheckDefault"
                                    >
                                      {item.name}
                                    </label>

                                    <label
                                      class="form-check-label"
                                      for="flexCheckDefault"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      {item.measure}
                                    </label>

                                    <label
                                      class="form-check-label"
                                      for="flexCheckDefault"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      {item.quantity} NOs
                                    </label>
                                  </div>
                                </li>
                              );
                            })}
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div class="accordion" id="Order_Track">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        Order Track
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse show"
                      data-bs-parent="#Order_Track"
                    >
                      <div class="accordion-body">
                        <div>
                          <ol>
                            {/* <li>
                              {response.storeItems[0].logs[0].status_text} -{" "}
                              {response.storeItems[0].logs[0].created_at}
                            </li> */}

                            {response.storeItems[0].logs.map((item, i) => {
                              return (
                                <li>
                                  {item.status_text} - {item.created_at}
                                </li>
                              );
                            })}
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
            <div className="d-block d-md-none col-12 col-md-6 col-lg-6 bottom-margin">
                <div>
                  <div class="accordion" id="Order_Payment">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Payment
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse show"
                        data-bs-parent="#Order_Payment"
                      >
                        <div class="accordion-body">
                          <div>
                            <ol>
                              <li>
                                Mode of payment : {response.payment[0].pay_mode}
                              </li>
                              {/* <li>
                                Settlement Status -{" "}
                                {response.payment[0].pay_mode}
                              </li> */}
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
              <div className="col-12  col-md-6 col-lg-6">
                <div style={{marginRight:20}}>
                <h5>Bill Details</h5>
                <div className="row">
                  <div className="col-lg-7">Items Cost</div>
                  <div className="col-lg-4 text-end">
                    <span className="strike-off">
                      ₹{response.info.items_cost}
                    </span>{" "}
                    ₹{response.info.item_total}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-7">Tax</div>
                  <div className="col-lg-4 text-end">
                    ₹{response.info.total_tax}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-7">Delivery Charges</div>
                  <div className="col-lg-4 text-end">
                    ₹{+response.info.delivery_charge}
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-lg-7">Total </div>
                  <div className="col-lg-4 text-end"> {+response.info.total_roundoff}</div>
                </div> */}
                <div className="row">
                  <div className="col-lg-7">Coupon</div>
                  <div className="col-lg-4 text-end">
                    - ₹{response.info.coupon_amount}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-7">Discount</div>
                  <div className="col-lg-4 text-end">
                    - ₹{response.info.offer_price}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-7">
                    <b>Total Price</b>{" "}
                  </div>
                  <div className="col-lg-4 text-end">
                    <b>₹{+response.info.total_roundoff}</b>
                  </div>
                </div>
                </div>
              </div>
              <div className="d-none d-md-block  col-md-6 col-12 col-lg-6">
                <div>
                  <div class="accordion" id="Order_Payment">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Payment
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse show"
                        data-bs-parent="#Order_Payment"
                      >
                        <div class="accordion-body">
                          <div>
                            <ol>
                              <li>
                                Mode of payment : {response.payment[0].pay_mode}
                              </li>
                              {/* <li>
                                Settlement Status -{" "}
                                {response.payment[0].pay_mode}
                              </li> */}
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
            {!isFranchisLogin() && (
              <>
                <div className="col-lg-12 mt-3 d-flex">
                  {ButtonsDisplay(response.info.order_status)}
                </div>
              </>
            )}
          </div>
        </main>
      )}
    </>
  );
}
export default OrderDetails;
