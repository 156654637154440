import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { consoleLog } from "../../../utils/log-utils";

function DbSettlementItem(props) {
//   const [data] = useState(props.item);
  const navigate=useNavigate();
  
//   const storeInfoPage = () => {
//     // dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
//     navigate("/uniqueStore", {
//       state: {
//         storeId: data.storeId,
//         storeName: data.storeName,
//         storeAddress: data.deliveryCity,
//         event:"orderWisePayments"
//       },
//     });
//   };
//   const deliveryPage = () => {
//     // dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
//     navigate("/uniqueDelivery", {
//       state: {
//         deliveryId: data.storeId,
//         dName: data.storeName,
        
//         event:"payments"
//       },
//     });
//   };
  return (
    <>
      <tr>
        <td>{props.sno}</td>
       
        {/* <td>#Svr210524</td> */}
        <td>1-12-2024</td>
        <td>
          <Link>yashu</Link>
        </td>
        <td>1</td>
        <td></td>
        
        
        <td ><Link></Link></td>
        <td></td>
        <td></td>
        
        {/* <td>{data.payment_status}</td> */}
        {/* <td>{data.transaction_date}</td>
        <td>{data.transaction_id}</td> */}
      </tr>
    </>
  );
}

export default DbSettlementItem;
