import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DeliveryOrderItem from "./delivery-order-item";
import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { DB_API_PATH } from "../../../network/config/apiPaths";
import { consoleLog } from "../../../utils/log-utils";

function Deliveryorder(props){
    const location=useLocation();
    // const db=location.state !== null ?location.state.db:"";
    // const city=location.state !== null?location.state.city:""
    const[selectMonth,setMonth]=useState("");
    const [resultList,setResultList]=useState([])
    consoleLog("id",props.id)
    const month=[
      {id:1,name:"JAN"},
      {id:2,name:"FEB"},
      {id:3,name:"MARCH"},
      {id:4,name:"APRIL"},
      {id:5,name:"MAY"},
      {id:6,name:"JUNE"},
      {id:7,name:"JULY"},
      {id:8,name:"AUG"},
      {id:9,name:"SEP"},
      {id:10,name:"OCT"},
      {id:11,name:"NOV"},
      {id:12,name:"DEC"},
    ]

    useEffect(()=>{
       dbordersApi();
    },[])

    const dbordersApi=async()=>{
      let data=await axios.get(API_CONFIG.API_HOST+DB_API_PATH.ORDER_LIST_PATH+`status=""&assignedId=${props.id}`,{
        headers:API_HEADERS
      })
      
      consoleLog("delivery orders",data.data.result)
      setResultList(data.data.result)
    }
    return(
        <>
        <div className="container-fluid dashboard">
        <div className="row mb-3">
          <div className="row justify-content-around tble">
            {/* <div className="col-6">
              <h3 className="dashboard-title">db.name</h3>
              <h4 className="dashboard-title">db.city</h4>
            </div> */}
            <div className="mr-auto col-6 text-end">
              {/* <button
                type="button"
                className="mr-auto btn btn-purple-bg"
                onClick={() => {
                  dispatch(
                    updateFormInfo({
                      displayForm: true,
                      formData: {},
                    })
                  );
                }}
              >
                + Add Category
              </button> */}
            </div>
          </div>
        </div>
        <div className='row'>
        <div className='col-4 col-md-2'>
        <label>Month</label>
          <select value={selectMonth} className='form-control'>
            <option>{"< FEB >"}</option>
            {
              month.map(item=>{
                return(
                  <option value={item.id}>{item.name}</option>
                )
              })
            }
          </select>
        </div>
        <div className='col-4 col-md-2'>

          <label>From</label>
          <input type="date" className='form-control'/>
            
          
        </div>
        <div className='col-4 col-md-2'>

          <label>To</label>
          <input type="date" className='form-control'/>
            
          
        </div>
       </div>
        <div className="row">
          

          <div className="col-12 card-1 mt-2 h-scroll">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Sno</th>
                  <th Scope="col">Date & Time</th>
                  <th scope="col">Order No</th>
                  <th scope="col">Order Amount</th>
                  <th scope="col">Store Name</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">D.Location</th>
                  <th scope="col">Status</th>
                  <th scope="col">Payment</th>
                  
                </tr>
              </thead>
              
                <tbody>
                  {
                    resultList !== null ? resultList.length>0&&
                    resultList.map(item=>{
                      return(
                        <DeliveryOrderItem item={item}/>
                      )
                    }):<>No data found</>
                  }
                 
                </tbody>
              
            </table>
          </div>
        </div>
      </div>
        </>
    )
}
export default Deliveryorder