import { useState } from "react";
import { useNavigate } from "react-router-dom";

function UpdateTransactionId(){
    const [dataForm, setDataForm] = useState({
        transId: "",
      });
      const navigate=useNavigate();
    const handleInputChanges=(e)=>{
         
         setDataForm({
            ...dataForm,
            [e.target.name]:e.target.val
         }
         )
    }
    return (
        <>
         
          <div
            style={{
              position: "fixed",
              backgroundColor: "rgba(0,0,0,0.5)",
              width: "100%",
              height: "100%",
              zIndex: "999",
              overflow: "scroll",
            }}
          >
            <div className="d-flex align-item-center justify-content-center">
              <div className="col-md-8">
                <div className="container">
                  <div className="row mt-5">
                    <div className="col-md-5 card-1 p-5">
                     
                        <h4 className="text-center">Update Transaction Id</h4>
                      
    
                      <div className="col-md-12">
                        <div className="form-group">
                          
                          <input
                            type="text"
                            name="transId"
                            className="form-control"
                            value={dataForm.transId}
                            onChange={(e) => handleInputChanges(e)}
                          />
    
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 20,
                            }}
                          >
                            <button
                              style={{ display: "flex", justifyContent: "center" }}
                              type="button"
                              className="btn btn-purple-bg"
                            //   onClick={() => onSubmtClick()}
                            >
                              Submit
                            </button>
                            <button
                              className="btn btn-white-bg"
                            //   onClick={() => {
                            //     dispatch(
                            //       updateFormInfo({
                            //         displayForm: false,
                            //         formData: {},
                            //       })
                            //     );
                            //   }}
                            onClick={()=>navigate(-1)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
}
export default UpdateTransactionId