import { useNavigate } from "react-router-dom"
import CouponsItem from "./all-coupon-item";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { BANNER_API_PATH, COUPONS_API_PATH } from "../../../network/config/apiPaths";
import { useDispatch, useSelector } from "react-redux";
import { deleteStatus, formUpdateData, largeImageStatus } from "../../../redux/slice/formUpdateSlice";
import DeleteBanner from "../../banners/crudOperations/deleteBanner";
import { updateMessage } from "../../../redux/slice/toastSlice";
import { consoleLog } from "../../../utils/log-utils";
import FullImageView from "../../../components/full-image";


function AllCoupons() {
  const navigate = useNavigate();
  const [apiResult, setApiResult] = useState([]);
  const dispatch = useDispatch();
  const showDeleteAlert = useSelector(deleteStatus);
  const [isLoading, setIsLoading] = useState(false)
  const formData = useSelector(formUpdateData);
  const displayLargeImage = useSelector(largeImageStatus);
  const [isProductUpdate, setIsProductUpdate] = useState(false);

  useEffect(() => {
    AllCoupons()
  }, [])

  const AllCoupons = async () => {
    setIsLoading(true)
    const data = await axios.get(API_CONFIG.API_HOST + COUPONS_API_PATH.COUPON_LIST_PATH + "ALL", {
      headers: API_HEADERS,
    })


    consoleLog("data....", data.data.result)
    setIsLoading(false)
    if (data.data.result) setApiResult(data.data.result)

  }

  const onSubmit = () => {

    dispatch(
      updateMessage({
        display: true,
        message: "Record deleted",
      })
    );
    // setBannerList([]);
    AllCoupons()

    // loadData();
  };


  return (
    <>
      {showDeleteAlert && (
        <DeleteBanner
          onSubmit={() => {
            onSubmit();
          }}
        />
      )}
      {displayLargeImage && (
        <>
          <FullImageView
            product={formData}
            onCancelClick={(reloadStatus) => {
              setIsProductUpdate(reloadStatus);
            }}
          />
        </>
      )}

      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-8">
              <h3 className="dashboard-title">App Coupons</h3>
            </div>
            <div className="mr-auto col-4 text-end">
              <button
                type="button"
                className="mr-auto btn add-button-color"
                onClick={() => { navigate("/addAllCoupon") }}

              >
                NEW
              </button>
            </div>
          </div>
        </div>
        <div className="row m-3">


          <div className="col-md-12 card-1 h-scroll">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Sno</th>
                  <th scope="col">Date</th>
                  <th scope="col">Coupon Code</th>
                  <th scope="col">Coupon Percentage</th>
                  <th scope="col">Coupon Image</th>

                  <th scope="col">Validity</th>
                  <th scope="col">Status</th>
                  <th scope="col">Total Orders</th>
                  <th scope="col">Actions</th>

                </tr>
              </thead>

              <tbody>
                {
                  isLoading ? (
                    <>Loading</>
                  ) :
                    apiResult && apiResult.length > 0 ? (
                      <>
                        {
                          apiResult.map((obj, i) => {
                            return (
                              <CouponsItem item={obj} sno={i + 1} key={obj.id} />
                            )
                          })
                        }
                      </>) : (
                      <>No data</>
                    )
                }

              </tbody>

            </table>
          </div>
        </div>
      </main>
    </>
  )
}

export default AllCoupons