import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducer/reducer";
import { thunk } from "redux-thunk";
const middleware = [thunk];
const store = configureStore({
  reducer: {
    rootReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      middleware,
      
      serializableCheck: false,
    }),
});

export default store;
 