import { useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useLocation, useNavigate } from "react-router-dom";
import { useToastMsg } from "../../hooks/useToastMsg";
import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { COUPONS_API_PATH } from "../../../network/config/apiPaths";
import { consoleLog } from "../../../utils/log-utils";

function NewCoupon() {
  const naviagte = useNavigate();
  const location=useLocation();
  const couponData=location.state!=null?location.state.product:"";
  consoleLog("coupon Item",couponData)
  const [inputValidation, setInputValidation] = useState({
    couponType: couponData!=""?couponData.type:"",
    storeSearch: "",
    couponName: couponData!=""?couponData.name:"",
    selectCategory: "",
    selectItems: "",
    description:couponData!=""?couponData.description:"",
    discount: couponData!=""?couponData.offer_percentage:"",
    couponCode:couponData!=""?couponData.code:"",
    from: couponData!=""?couponData.start_date:"",
    to: couponData!=""?couponData.end_date:"",
    max_limit:couponData!=""?couponData.max_limit:"",
    minOrder: couponData!=""?couponData.min_order_value:"",
    claims: couponData!=""?couponData.number_of_claims:"",
    image: "",
    terms: couponData!=""?couponData.terms:"",
    checking: false,
  });
  const [date,setDate]=useState("");
  const[err,setErr]=useState("");

  useEffect(() => {
    datesValidation();
  }, [inputValidation]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValidation({
      ...inputValidation,
      [name]: value,
    });
  };

  const reset = () => {
    setInputValidation({
      ...inputValidation,
      couponType: "",
      storeSearch: "",
      couponName: "",
      selectCategory: "",
      selectItems: "",
      discount: "",
      couponCode:"",
      from: "",
      to: "",
      max_limit:"",
      minOrder: "",
      claims: "",
      image: "",
      terms: "",
      checking: false,
      max_limit:"",
    });
  };
  
  const [success, warning] = useToastMsg(
    couponData===""?"Coupon Successfully Created":"Coupon Successfully Updated",
    "Rquired All Input Feilds",
    reset
  );

  const Inputvalidation = () => {
    
      inputValidation.couponType === "--Coupon Type--" ||
 
      inputValidation.couponName === "" ||
     
 
      inputValidation.discount === "" ||
      inputValidation.from === "" ||
      date === "" ||
      inputValidation.minOrder === "" ||
      inputValidation.claims === "" ||
     
      inputValidation.terms === "" ||
      inputValidation.checking === false
      ? warning()
      :AllCOuponCreate();
  }

  async function AllCOuponCreate() {
    const data = await fetch(API_CONFIG.API_HOST + COUPONS_API_PATH.CREATE_COUPON, {
      method:"POST",
      headers: API_HEADERS,
      body: couponData===""? JSON.stringify({
        name: inputValidation.couponName,
        storeId: "", //only for Store
        code: inputValidation.couponCode,
        offerPercentage: inputValidation.discount,
        description: inputValidation.description,
        uniqueUser: "YES", // YES or NO
        type: "ALL", // ALL / LOCATION / STORE
        limitApply: "YES", // YES or NO
        limitCount: "300",
        maxLimit: inputValidation.max_limit, // offer max upto optional.
        startDate: inputValidation.from,
        endDate: inputValidation.to,
        daysLimit: "YES", // YES or NO, if days limit yes-> start and end date consider
        minOrderValue: inputValidation.minOrder,
        claims: inputValidation.claims,
        terms:inputValidation.terms,
      }):JSON.stringify({
        name: inputValidation.couponName,
        storeId: "", //only for Store
        code: inputValidation.couponCode,
        offerPercentage: inputValidation.discount,
        description: inputValidation.description,
        uniqueUser: "YES", // YES or NO
        type: "ALL", // ALL / LOCATION / STORE
        limitApply: "YES", // YES or NO
        limitCount: "300",
        maxLimit: inputValidation.max_limit, // offer max upto optional.
        startDate: inputValidation.from,
        endDate: inputValidation.to,
        daysLimit: "YES", // YES or NO, if days limit yes-> start and end date consider
        minOrderValue: inputValidation.minOrder,
        claims: inputValidation.claims,
        terms:inputValidation.terms,
        id:couponData.id
      })
    })
    
    consoleLog("data",data)
   
    consoleLog("status code",data.status)
    if(data.status===200){
      success()
    }
  }
  

  const datesValidation= () =>{
    let to=inputValidation.to;
    
      {
        consoleLog("to",inputValidation.to)
      }
      
        if(new Date(inputValidation.from)>new Date(to)){
          setErr("Enter Valid Date")
        }
        else{
          // <span className="text-danger">Enter valid date</span>
         setDate(to)
         setErr('')
        }
       
        
        
        
      
      {
        consoleLog("To date..", date)
      }
     
      
    
  }

  return (
    <>
      <main className="container-fluid dashboard">
        {/* <div className="row m-3">
          <div className="col-4">
            <select
              className="form-control"
              name="couponType"
              value={inputValidation.couponType}
              onChange={handleChange}
            >
              <option>{"--Coupon Type--"}</option>
              <option>Almonds</option>
              <option>Badham</option>
            </select>

            {inputValidation.couponType === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>

          <div className="col-4">
            <input
              type="search"
              className="form-control"
              placeholder="Store Search"
              name="storeSearch"
              value={inputValidation.storeSearch}
              onChange={handleChange}
            />

            {inputValidation.storeSearch === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
        </div> */}

        <div className="row m-3">
          <div className="col-12 col-md-4 bottom-margin">
            <label>Coupon Name</label>
            <input
              className="form-control"
              name="couponName"
              type="text"
              placeholder="Coupon Name"
              value={inputValidation.couponName}
              onChange={handleChange}
            />

            {inputValidation.couponName === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-12 col-md-4 bottom-margin">
            <label>Coupon Code</label>
            <input
              className="form-control"
              name="couponCode"
              type="text"
              placeholder="Coupon Code"
              value={inputValidation.couponCode}
              onChange={handleChange}
            />

            {inputValidation.couponCode === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-12 col-md-4">
            <label>Maximum Limit</label>
            <input
              className="form-control"
              name="max_limit"
              type="text"
              placeholder="Maximum limit"
              value={inputValidation.max_limit}
              onChange={handleChange}
            />
          </div>
          {/* <div className="col-4">
            <input
              className="form-control"
              name="couponName"
              type="text"
              placeholder="Coupon Name"
              value={inputValidation.couponName}
              onChange={handleChange}
            />

            {inputValidation.couponName === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-4">
            <select
              className="form-control"
              name="selectCategory"
              type="text"
              value={inputValidation.selectCategory}
              onChange={handleChange}
            >
              <option>{"--Select Category--"}</option>
              <option>All Seeds</option>
              <option>Nuts</option>
            </select>

            {inputValidation.selectCategory === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-4">
            <select
              className="form-control"
              name="selectItems"
              value={inputValidation.selectItems}
              onChange={handleChange}
            >
              <option>{"--Select Items--"}</option>
              <option>mirchi</option>
            </select>

            {inputValidation.selectItems === "" && (
              <span className="text-danger">*Required</span>
            )} 
            </div> */}
        </div>

        <div className="row m-3">
          <div className="col-xs-6 col-sm-6 col-md-4 col-lg-2">
            <label>Discount</label>
            <input
              type="number"
              placeholder="%"
              className="form-control"
              name="discount"
              value={inputValidation.discount}
              onChange={handleChange}
            />

            {inputValidation.discount === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-md-8 col-xs-6 col-sm-6 col-lg-5">


            <div className="d-flex flex-row">
              <div style={{ marginRight: 5 }}>
                <label>From</label>
                <input
                  type="date"
                  placeholder="From"
                  className="form-control"
                  name="from"
                  value={inputValidation.from}
                  onChange={handleChange}
                />

                {(inputValidation.from === "" || inputValidation.to === "") && (
                  <span className="text-danger">*Required</span>
                )}
                {err !=="" && (
                  <span className="text-danger">{err}</span>
                )}
                
              </div>
              <div>
                <label>To</label>
                <input
                  type="date"
                  placeholder="To"
                  className="form-control"
                  name="to"
                  value={inputValidation.to}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className=" col-12 col-md-4 col-lg-2">
            <label>Min Order</label>
            <input
              type="number"
              placeholder="Rs 300"
              className="form-control"
              // style={{marginLeft:10}}
              name="minOrder"
              value={inputValidation.minOrder}
              onChange={handleChange}
            />

            {inputValidation.minOrder === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-md-4 col-xs-6 col-sm-6 col-lg-3">
            <label>Number of Claims</label>
            <input
              type="number"
              placeholder="5"
              className="form-control"
              name="claims"
              value={inputValidation.claims}
              onChange={handleChange}
            />

            {inputValidation.claims === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
        </div>

        <div className="row m-3">
          <div className="col-12 col-md-6">
            <label>Description</label>
            <input
              type="text"
              className="form-control"
              name="description"
              value={inputValidation.description}
              style={{ border: "1px solid #80df6d", height: 150 }}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <label>Terms and Conditions</label>
            <textarea
              type="text"
              name="terms"
              rows="10"
              cols="100"
              value={inputValidation.terms}
              onChange={handleChange}
              className="form-control"
              style={{ border: "1px solid #80df6d", height: 150 }}
            ></textarea>

            {inputValidation.terms === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
        </div>

        <div className="row m-3 mt-0">

          <div className="col-12 col-md-6">
            <input
              id="termsConditions"
              onClick={(e) =>
                setInputValidation({
                  ...inputValidation,
                  checking: e.target.checked,
                })
              }
              type="checkbox"
              style={{ width: 20, marginTop: 50, border: "1px solid #80df6d" }}
            />
            <label htmlFor="termsConditions">
              I am legally responsible for this business and have permission to
              create this coupon.{" "}
            </label>
            <br />
            {inputValidation.checking === false && (
              <span className="text-danger">*Required</span>
            )}
            <div className="d-flex flex-row  m-5">
              <button
                className="btn"
                style={{
                  border: "1px solid black",
                  marginRight: 5,
                  width: 100,
                }}
                onClick={() => naviagte(-1)}
              >
                Back
              </button>
              {couponData===""?<button className="btn background-color-change" onClick={Inputvalidation}>
                Generate
              </button>:<button className="btn background-color-change" onClick={Inputvalidation}>
                Update
              </button>} 
            </div>
            <ToastContainer />
            {
            consoleLog("final output..", inputValidation)}
            
          </div>
        </div>
      </main>
    </>
  );
}

export default NewCoupon;
