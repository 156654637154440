import { useEffect, useState } from "react";

import axios from "axios";


import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { PAYMENTS_API_PATH } from "../../../network/config/apiPaths";

import { consoleLog } from "../../../utils/log-utils";
import FranchiseSettelementItem from "./franchise-settelement-item";

function FranchiseSettelements() {
  const [selectMonth, setMonth] = useState("");
  const [paymentList, setPaymentList] = useState([]);
  const month = [
    { id: 1, name: "JAN" },
    { id: 2, name: "FEB" },
    { id: 3, name: "MARCH" },
    { id: 4, name: "APRIL" },
    { id: 5, name: "MAY" },
    { id: 6, name: "JUNE" },
    { id: 7, name: "JULY" },
    { id: 8, name: "AUG" },
    { id: 9, name: "SEP" },
    { id: 10, name: "OCT" },
    { id: 11, name: "NOV" },
    { id: 12, name: "DEC" },
  ];

  useEffect(() => {
    paymentApiCall();
  }, []);

  const paymentApiCall = async () => {
    let data = await axios.get(
  API_CONFIG.API_HOST + PAYMENTS_API_PATH.LIST_PATH+`?foId=${localStorage.getItem("userId")}`,
     {
        headers: API_HEADERS,
      }
    );
    consoleLog("data",data.data.result);
    setPaymentList(data.data.result);
  };

  return (
    <>
      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="col-12">
            <h3>Settlements</h3>
          </div>
        <div className="col-6 col-md-4 col-lg-3 m-0">
              
            <div className="mr-auto col-6 text-end">
              {/* <button
                type="button"
                className="mr-auto btn btn-purple-bg"
                onClick={() => {
                  dispatch(
                    updateFormInfo({
                      displayForm: true,
                      formData: {},
                    })
                  );
                }}
              >
                + Add Category
              </button> */}
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-4 col-md-2">
            <label>Month</label>
            <select value={selectMonth} className="form-control">
              <option>{"< FEB >   (current year)"}</option>
              {month.map((item) => {
                return <option value={item.id}>{item.name}</option>;
              })}
            </select>
          </div>
          <div className="col-4 col-md-2">
            <label>From</label>
            <input type="date" className="form-control" />
          </div>
          <div className="col-4 col-md-2">
            <label>To</label>
            <input type="date" className="form-control" />
          </div>
          {/* <div className="col-4 col-md-2 top-margin">
            <label>Location</label>
           <select className="form-control">
            <option>{"--select city--"}</option>
           </select>
          </div> */}
          <div className="col-4 top-margin" style={{marginLeft:"auto"}}>
          <label></label><br/>
          <div className="d-flex flex-row justify-content-end">
          <button className="btn btn-success">Download Excel</button>
          </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-12 card-1 h-scroll">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Sno</th>
                 
                  {/* <th scope="col">Settlement ID</th> */}
                  <th scope="col">Date</th>
                  
                  <th scope="col">Transaction Amount</th>
                  <th scope="col">Transaction Id</th>
                  {/* <th scope="col">Date</th>
                  <th scope="col">Transaction ID</th> */}
                </tr>
              </thead>

              <tbody>
                {paymentList !== null?
                  paymentList.length > 0 &&
                  paymentList.map((item, i) => {
                    return (
                      <>
                        <FranchiseSettelementItem item={item} sno={i} />
                      </>
                    );
                  }):<>No data found</>
                }
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </>
  );
}

export default FranchiseSettelements;
