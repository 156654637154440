import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { consoleLog } from "../../utils/log-utils";




function LocationItem(props) {
  const [data] = useState(props.list);
  const navigate=useNavigate();
  consoleLog("data..", data);

  const locationStore=()=>{
    navigate("/locationStores",{state:{latitude:data.latitude,longitude:data.longitude,name:data.location_name}})
  }
  
  return (
    <>
      <tr>
        <td>{props.sno}</td>
       
        <td>{data.city}</td>
        <td onClick={()=>locationStore()}>
            <Link>
            {data.location_name}
            </Link>
        </td>
        
        
        {/* <td>{data.transaction_date}</td>
        <td>{data.transaction_id}</td> */}
      </tr>
    </>
  );
}

export default LocationItem;
